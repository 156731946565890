import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  useStylesEmployees,
  AddButton,
  SubmitButton,
  CancelButton,
  pageOptions,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  AutoCompleteCustom,
  CustomSwitch,
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { ResourcesViewModel } from "../../ViewModel/Settings/ResourcesViewModel.js";
import SettingsSkeleton from './SettingsSkeleton.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



export default function PublicResources() {

  const [
    //states
    {
      title, text, open, disable, type, code,
      Resources, name, description, companyCode,
      localconfiguration, mode, loading,
      pageNumber, pageNumberRef, totalPage,
      page, loadingProcess, resourceTypeList,
      resourceUnitList, units, UnitValue,
      vehicle,fuelPump,
    },
    //function
    {
      SaveResources, handleClickOpen, handleClose,
      handleChangeInput, pageChange, handleChange,
      setType, setUnits, setUnitValue,
    },
  ] = ResourcesViewModel();

  const classes = useStylesEmployees();

  return (
    <>
      {Resources ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="h5">Name</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Description</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Units</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">code</Typography>
                    </Grid>

                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
                      {localconfiguration.resource_settings ?
                        <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                          <AddIcon />
                        </AddButton>
                        : ''}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcess ?
            <SettingsSkeleton status='page_load' />
            :
            <>
              {Resources.map((resource, index) => {
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`resource_list_${index}`}
                      style={{ userSelect: 'auto', cursor: 'auto', alignItems: 'flex-start' }}
                    >

                      <Grid container onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={2} >
                          <Typography noWrap variant="h6" id={`resource_name_${index}`}>{resource.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`resource_desc_${index}`}>{resource.description}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`resource_units_${index}`}>{resource.units}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`resource_type_${index}`}>{resource.type === 'chemical' ? 'Material' : resource.type}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`resource_type_${index}`}>{resource.code}</Typography>
                        </GridCustom>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                          <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(resource, 'edit')} >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                  </CustomAccordion>
                );
              })}

              <Dialog
                id="resource_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="resource_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <div>
                      <TextFieldCustom
                        size='small'
                        id="name_text"
                        label="Name"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="name"
                        value={name ?? ''}
                        onChange={handleChangeInput}
                      />

                      {resourceTypeList ?
                        <AutoCompleteCustom
                          name="type"
                          id="combo-box-resourcetype"
                          options={resourceTypeList ? resourceTypeList : []}
                          getOptionLabel={(option) => option.name ?? ''}
                          getOptionSelected={(option, value) => option.id === value.value}
                          fullWidth={true}
                          renderInput={(params) => <TextFieldCustom {...params} label="Type" variant="outlined" />}
                          style={{ paddingTop: '8px' }}
                          onChange={(event, newValue) => {
                            newValue === null ?
                              setType({ id: 0, name: '', value: '' })
                              :
                              setType({ id: newValue.id, name: newValue.name, value: newValue.value })
                          }}
                          value={type}
                        />
                        :
                        <SettingsSkeleton status='component_load' />}

                      {resourceUnitList ? (
                        <AutoCompleteCustom
                          freeSolo
                          value={UnitValue}
                          onChange={(event, newValue) => {
                            if (newValue === 'Others') {
                              setUnits('');
                            }
                            else {
                              setUnitValue(newValue);
                            }
                          }}
                          inputValue={units}
                          onInputChange={(event, newInputValue) => {
                            setUnits(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={resourceUnitList}
                          style={{ paddingTop: '16px' }}
                          renderInput={(params) => <TextFieldCustom {...params} label="Units" variant="outlined" />}
                        />
                      ) : (
                        <SettingsSkeleton status='component_load' />
                      )}

                      <TextFieldCustom
                        size='small'
                        id="description_text"
                        label="code"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="code"
                        value={code ?? ''}
                        onChange={handleChangeInput}
                      />

                      <TextFieldCustom
                        size='small'
                        id="description_text"
                        label="company_code"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="company_code"
                        value={companyCode ?? ''}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 3 }}
                      />

                      <TextFieldCustom
                        size='small'
                        id="description_text"
                        label="Description"
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        name="description"
                        value={description ?? ''}
                        onChange={handleChangeInput}
                      />
                        <span style={{ display: 'inline-flex', marginTop:'15px' }} >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="fuel_pump" checked={fuelPump} onChange={handleChangeInput} />
                              }
                              label="Fuel Pump"
                            />
                          </FormGroup>

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="vehicle" checked={vehicle} onChange={handleChangeInput} />
                              }
                              label="Vehicle"
                            />
                          </FormGroup>
                        </span>
                    </div>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <SettingsSkeleton status='component_load' />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveResources()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {Resources ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        : " "}
    </>
  );
}