import React, { useEffect } from 'react';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from '../style.js';

var searchTimeout;

export const Settings = () => {
  const [searchEmp, setSearchEmp] = React.useState(null);
  const [searchCus, setSearchCus] = React.useState(null);
  const [searchHol, setSearchHol] = React.useState(null);
  const [searchAct, setSearchAct] = React.useState(null);
  const [searchAwd, setSearchAwd] = React.useState(null);
  const [searchSeOp, setSearchSeOp] = React.useState(null);
  const [searchTimeOp, setSearchTimeOp] = React.useState(null);
  const [searchInt, setSearchInt] = React.useState(null);
  const [searchRes, setSearchRes] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const classes = useStyles();

  useEffect(() => {
    const changeTab = (eventData) => {
      setTab(eventData.text);
    }
    const changeTabListener = EventEmitter.addListener('changeTab', changeTab);

    return () => {
      changeTabListener.remove();
    }
  }, [])

  const handleChange = (e) => {
    if (e.target.name === 'searchEmployee') {
      setSearchEmp(e.target.value);
    }
    else if (e.target.name === 'searchCustomers') {
      setSearchCus(e.target.value);
    }
    else if (e.target.name === 'searchHolidays') {
      setSearchHol(e.target.value);
    }
    else if (e.target.name === 'searchActivities') {
      setSearchAct(e.target.value);
    }
    else if (e.target.name === 'searchIntegration') {
      setSearchInt(e.target.value);
    }
    else if (e.target.name === 'searchAwards') {
      setSearchAwd(e.target.value);
    }
    else if (e.target.name === 'searchSessionOptions') {
      setSearchSeOp(e.target.value);
    }
    else if (e.target.name === 'searchTimesheetOptions') {
      setSearchTimeOp(e.target.value);
    }
    else if (e.target.name === 'searchResource') {
      setSearchRes(e.target.value);
    }
  }

  if (tab === 0) {
    const searchEmployee = (event) => {
      if (event.key === "Enter") {
        searchEmployeeEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchEmployeeEmit();
        }, 500)
      }
    }

    const searchEmployeeEmit = () => {
      EventEmitter.emit('searchEmployee', {
        text: searchEmp
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchEmployee"
        onChange={handleChange}
        onKeyUp={(e) => searchEmployee(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

    )
  }
  else if (tab === 1) {
    const searchCustomers = (event) => {

      if (event.key === "Enter") {
        searchCustomerEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchCustomerEmit();
        }, 500)
      }
    }

    const searchCustomerEmit = () => {
      EventEmitter.emit('searchCustomer', {
        text: searchCus
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchCustomers"
        onChange={handleChange}
        onKeyUp={(e) => searchCustomers(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 2) {
    const searchHolidays = (event) => {

      if (event.key === "Enter") {
        searchHolidayEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchHolidayEmit();
        }, 500)
      }
    }

    const searchHolidayEmit = () => {
      EventEmitter.emit('searchHoliday', {
        text: searchHol
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchHolidays"
        onChange={handleChange}
        onKeyUp={(e) => searchHolidays(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 3) {
    const searchActivities = (event) => {

      if (event.key === "Enter") {
        searchActivityEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchActivityEmit();
        }, 500)
      }
    }

    const searchActivityEmit = () => {
      EventEmitter.emit('searchActivity', {
        text: searchAct
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchActivities"
        onChange={handleChange}
        onKeyUp={(e) => searchActivities(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 4) {
    const searchResource = (event) => {

      if (event.key === "Enter") {
        searchResourceEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchResourceEmit();
        }, 500)
      }
    }

    const searchResourceEmit = () => {
      EventEmitter.emit('searchResource', {
        text: searchRes
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchResource"
        onChange={handleChange}
        onKeyUp={(e) => searchResource(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 5) {
    const searchAwards = (event) => {

      if (event.key === "Enter") {
        searchAwardEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchAwardEmit();
        }, 500)
      }
    }

    const searchAwardEmit = () => {
      EventEmitter.emit('searchAwards', {
        text: searchAwd
      })
    }

    return (
      <TextField
        key={tab}
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchAwards"
        onChange={handleChange}
        onKeyUp={(e) => searchAwards(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 6) {
    const searchSessionOptions = (event) => {

      if (event.key === "Enter") {
        searchSessionOptionsEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchSessionOptionsEmit();
        }, 500)
      }
    }

    const searchSessionOptionsEmit = () => {
      EventEmitter.emit('searchSessionOptions', {
        text: searchSeOp
      })
    }

    return (
      <TextField
        key={tab}
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchSessionOptions"
        onChange={handleChange}
        onKeyUp={(e) => searchSessionOptions(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else if (tab === 7) {
    const searchTimesheetOptions = (event) => {
      if (event.key === "Enter") {
        searchTimesheetOptionsEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchTimesheetOptionsEmit();
        }, 500)
      }
    }

    const searchTimesheetOptionsEmit = () => {
      EventEmitter.emit('searchTimesheetOptions', {
        text: searchTimeOp
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchTimesheetOptions"
        onChange={handleChange}
        onKeyUp={(e) => searchTimesheetOptions(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

    )
  }
  else if (tab === 8) {
    const searchIntegration = (event) => {

      if (event.key === "Enter") {
        searchIntegrationEmit();
      }
      else {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
          searchIntegrationEmit();
        }, 500)
      }
    }

    const searchIntegrationEmit = () => {
      EventEmitter.emit('searchIntegration', {
        text: searchInt
      })
    }

    return (
      <TextField
        key={tab}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.search}
        name="searchIntegration"
        onChange={handleChange}
        onKeyUp={(e) => searchIntegration(e)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }
  else {
    return ('');
  }
}