import React from 'react';
import {
  Typography,
  Card,
  Grid,
  CardContent,
  Divider,
  Box,
} from '@material-ui/core';
import { TextFieldCustom, TextFieldCustom3, AutoCompleteCustom } from './style.js';
import HailIcon from '@mui/icons-material/Hail';
import { SubmitButton } from '../Settings/style.js';
import { LeaveSupervisorChip, useStylesConfiguration, CustomSwitch } from './style.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ConfigurationViewModel } from "../../ViewModel/Settings/ConfigurationViewModel";
import FormGroup from '@mui/material/FormGroup';
import SettingsSkeleton from './SettingsSkeleton';

export default function Configuration() {
  const [
    //states
    {
      configurationRef, testEmail, maxBreakTimeRef, timezoneRef, timezoneFilterChosenRef,
      emailNotificationStatusRef, checkedRef, loading, enableTestEmail, emailError,
    },
    //setStates
    {
      setTimezoneFilterChosen,
    },
    //function
    {
      handleChangeInput, switchHandleChange, toggleChecked, SaveConfiguration
    }
  ] = ConfigurationViewModel();

  const classes = useStylesConfiguration();

  return (
    <div>
      {configurationRef.current ?
        <Card elevation={1} className={classes.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Box sx={{ borderRadius: '50%' }}>…
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}    
                >
                  <Grid item xs={12}>
                    Email Settings
                    <Divider variant="inset" />
                  </Grid> 
                  <Grid item xs={5}>
                    <Typography variant="h6">Enable Test Email:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: 'flex' }}>
                      <FormGroup>
                        <FormControlLabel name="enable_test_email" style={{ marginRight: '5px' }} control={<CustomSwitch aria-label='Enable Test Email' checked={enableTestEmail} onChange={switchHandleChange} />} />
                      </FormGroup>
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="h6">Test Email:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextFieldCustom3
                      size='small'
                      id="test_email"
                      label="Test Email"
                      variant="outlined"
                      margin="normal"
                      name="test_email"
                      type="email"
                      disabled={enableTestEmail ? false : true}
                      value={testEmail ? testEmail : ''}
                      error={emailError}
                      helperText={emailError ? "Please enter a valid email address" : ""}
                      onChange={handleChangeInput}
                      style={{ width: '90%' }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="h6">Enable Email Notifications:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: 'flex' }}>
                      <FormGroup>
                        <FormControlLabel name="enable_email_notifications" style={{ marginRight: '5px' }} control={<CustomSwitch aria-label='Enable Email Notifications' checked={emailNotificationStatusRef.current} onChange={switchHandleChange} />} />
                      </FormGroup>
                    </div>
                  </Grid>
                </Grid>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}  
                >
                  <Grid item xs={12}>
                    General Settings
                    <Divider variant="inset" />
                  </Grid> 
                  <Grid item xs={5}>
                    <Typography variant="h6">Max Break Time:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextFieldCustom3
                      size='small'
                      id="max_break_time"
                      label="Max Break Time"
                      variant="outlined"
                      margin="normal"
                      name="max_break_time"
                      type="number"
                      value={maxBreakTimeRef.current ? maxBreakTimeRef.current : ''}
                      onChange={handleChangeInput}
                      style={{ width: '80%' }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="h6"> Default Timezone:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <AutoCompleteCustom
                      aria-label='Select Timezone'
                      id="combo-box-timezone"
                      style={{ width: '80%' }}
                      options={timezoneRef.current || []}
                      getOptionLabel={(option) => option.value || ""}
                      getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={(params) => <TextFieldCustom {...params} label="Select Timezone" size='small' variant="outlined" />}
                      onChange={(event, newValue) => {
                        newValue === null ?
                          setTimezoneFilterChosen({ id: 0, name: '', value: '' })
                          :
                          setTimezoneFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                      }}
                      value={timezoneFilterChosenRef.current ? timezoneFilterChosenRef.current : ''}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="h6">Leave Supervisor :</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: 'flex', minHeight: '40px', alignItems: 'center' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <CustomSwitch aria-label='Leave Supervisor' checked={checkedRef.current} onChange={toggleChecked} />
                          }
                          label={
                            <div>
                              {checkedRef.current ?
                                <LeaveSupervisorChip
                                  style={{ marginLeft: '5px' }}
                                  variant="outlined"
                                  size="small"
                                  icon={<HailIcon sx={{ color: '#FFFFFF' }} />}
                                />
                                : ''}
                            </div>
                          }
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid 
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}    
            >
              {configurationRef.current.config_settings ?
                loading ?
                  <SettingsSkeleton status='save_load' />
                  :
                  <SubmitButton
                    id="save_btn"
                    size="small"
                    variant="contained"
                    onClick={() => SaveConfiguration()}
                  >
                    Save
                  </SubmitButton>
                : ''}
            </Grid>
          </CardContent>
        </Card>
        :
        <SettingsSkeleton status='configuration_load' />
      }
    </div>
  );
}