import React, { useState, useEffect } from 'react';
import { AwardsController, PayrunListController, AwardRulesController } from '../../Controller/Settings/Awards.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';
import linq from "linq";
import {
  ListItem,
  ListItemText,
  Grid,
  ListItemSecondaryAction,
  List
} from '@material-ui/core';
import { TextFieldCustom, AutoCompleteCustom } from 'src/views/Settings/style.js';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

let page = 1;

export const AwardsViewModel = () => {
  const [initialized, setInitialized] = useState(false);
  const [Awards, setAwards] = useState(null);

  const [searchAwd, setSearchAwd] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  
  const [description, setDescription] = useState('');
  const [payrun, setPayrun, payrunRef] = useStateRef('');
  const [payrunFilterChosen, setPayrunFilterChosen, payrunFilterChosenRef] = useStateRef('');

  const [AwardsData, setAwardsData] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [mode, setMode] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);
  
  const [awardRules, setAwardRules] = useState("");
  const [awardRulesFilterChosen, setAwardRulesFilterChosen, awardRulesFilterChosenRef] = useStateRef([]);
  const [awardRulesList, setAwardRulesList] = useState("");

  const [totalPage, setTotalPage] = useState("");
  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("awards_page")?localStorage.getItem("awards_page"):50);

  const [newValue, setNewValue] = useState([]);
  const [value] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadAwards();
      loadAwardRules();
    }

    const searchAwards = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchAwd(eventData.text);
      loadAwards(eventData.text);
    }

    const listener = EventEmitter.addListener('searchAwards', searchAwards);
    
    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = code === '' || name === '';
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  const loadAwards = async (data) => {
    var AwardsResults = await AwardsController(data,page);
    setAwards(AwardsResults.data);
    setTotalPage(AwardsResults.meta.filter_count);
    setPayrun(await PayrunListController());
    setLoadingProcess(false);
  };

  const loadAwardRules = async () => {
    try
    {
      var AwardRulesResult = await AwardRulesController();
      setAwardRules(AwardRulesResult.data);
      setAwardRulesList(AwardRulesResult.data);
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadAwards(searchAwd);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("awards_page", JSON.parse(event.target.value));
    page = 1;
    loadAwards(searchAwd);
  };

  const handleClickOpen = (Awards, mode) => {
    setOpen(true);
    if (mode === 'edit') {
      setCode(Awards.code);
      setName(Awards.name);
      setDescription(Awards.description); 
      setPayrunFilterChosen({ id: Awards.payrun, name: '', value: Awards.payrun });

      if(Awards.award_rules.length > 0)
      {
        let result = [];
        Awards.award_rules.map(data => {
          result = [...result, { award_rules_id:data.id,id:data.award_rules_id.id, type:0,value:data.value,code:data.award_rules_id.code,name:data.award_rules_id.name}];
        })
        setAwardRulesFilterChosen(result);
        loadAwardRulesList();
      }
      else
      {setAwardRulesFilterChosen([]);}

      setAwardsData(Awards);
      setMode('edit')
      setTitle('Edit Awards');
      setText('');
    }
    else if (mode === 'add') {
      setCode('');
      setName('');
      setDescription('');
      setPayrunFilterChosen({ id: 0, name: '', value: '' });
      setAwardRulesFilterChosen([]);
      setMode('add');
      setTitle('Add Awards');
      setText('');
    }
    else {
      setAwardsData(Awards);
      setMode('delete');
      setTitle('Delete Awards');
      setText('Are you Sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'code') {
      setCode(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'description') {
      setDescription(value);
    }
    else if (name === 'payrun') {
      setPayrunFilterChosen(value);
    }
  }

  const SaveAwards = async () => {
    setLoading(true);
    setLoadingProcess(true);
    
    if (mode === 'edit') 
    {
      try {
        await directus.updateItem('awards', AwardsData.id, {
          code:code?code:'',
          name:name?name:'',
          description:description?description:'',
          payrun:payrunFilterChosenRef.current?payrunFilterChosenRef.current.value:'',
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      let createData = [];
      let updateData = [];
      let deleteData = '';
      let deleteInput = [];

      //================================== Edit, Add, Delete Awards Award Rules Relation in Edit Mode Save Award ===================================//
      if(awardRulesFilterChosenRef.current.length > 0){
        awardRulesFilterChosenRef.current.map(data => {
          //when the type is not 0 (nothing change type) and id is not encrypted id (less than 10. it should be 36 length)
          if(data.type !== 0 && data.id.toString().length > 10 ){
            if(data.type === 2 ){
              const filterInputCreate = linq.from(AwardsData.award_rules)
              .where(x=>x.award_rules_id.id===data.id && x.value === data.value).toArray();

              if (filterInputCreate.length === 0) {
                createData = [...createData, { awards_id:AwardsData.id, award_rules_id:data.id, value: data.value}];
              }
            }
            else if(data.type === 1 ){
              const filterInput = linq.from(AwardsData.award_rules)
              .where(x=>x.id===data.award_rules_id).toArray();

              if (filterInput.length > 0) {
                updateData = [...updateData, { id:data.award_rules_id, awards_id:AwardsData.id, award_rules_id:data.id, value: data.value}];
              }
            }
            else if (data.type === 3){
              const filterInput = linq.from(AwardsData.award_rules)
              .where(x=>x.award_rules_id.id===data.id).toArray();

              if (filterInput.length > 0) {
                if (deleteData === '') {
                  deleteData = data.award_rules_id;
                }
                else {
                  deleteData = data.award_rules_id + ',' + deleteData;
                }
              }
            }
          }
        })
      
        if (createData.length > 0){
          try {
            await directus.createItems('awards_award_rules', createData);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }
        if (updateData.length > 0){
          try {
            await directus.updateItems('awards_award_rules', updateData);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }
        if (deleteData !== '') {
          var AwardsAwardRulesResult = await directus.getItems('awards_award_rules', {
            fields: '*',
            filter: {
              awards_id: { eq: AwardsData.id },
              id: { in: deleteData }
            }
          });
  
          AwardsAwardRulesResult.data.map(data => {
            deleteInput = [...deleteInput, data.id];
          })
        
          try {
            await directus.deleteItems('awards_award_rules', deleteInput);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }   
      }
    }
    else if (mode === 'add') {
      let awardRulesData=[];

      if(awardRulesFilterChosenRef.current.length > 0) {
        awardRulesFilterChosenRef.current.map(data => {
          if(data.id.toString().length > 10 && data.type !== 4)
          {
            awardRulesData = [...awardRulesData,  {"award_rules_id":{"id":data.id}, value: data.value}];
          }
        })
      } 
      else
      {
        awardRulesData=[];
      }

      try {
        await directus.createItem('awards', {
          code:code?code:'',
          name:name?name:'',
          description:description?description:'',
          payrun:payrunFilterChosenRef.current?payrunFilterChosenRef.current.value:'',
          award_rules:awardRulesData,
          status:'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {

      let deleteData = '';
      //================================== Delete Awards Award Rules Relation ===================================//
      AwardsData.award_rules.map(data => {
        const filterInput = linq.from(awardRulesFilterChosenRef.current)
          .where(x=>x.code === data.award_rules_id.code).toArray();

        if(AwardsData.award_rules)
        {
          if (filterInput.length === 0) {
            if (deleteData === '') {
              deleteData = data.id;
            }
            else {
              deleteData = data.id + ',' + deleteData;
            }
          }
        }
      })

      if (deleteData !== '') {
        var AwardsAwardRulesResult = await directus.getItems('awards_award_rules', {
          fields: '*',
          filter: {
            awards_id: { eq: AwardsData.id },
            id: { in: deleteData }
          }
        });

        let deleteInput = [];

        AwardsAwardRulesResult.data.map(data => {
          deleteInput = [...deleteInput, data.id];
        })

        try {
          await directus.deleteItems('awards_award_rules', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      try {
        await directus.deleteItem('awards', AwardsData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    loadAwards();
    setLoading(false);
    handleClose();
  }

  const addAwardRules = (award_rules, value) => {
    
    if(award_rules === null)
    {
      let data = [];
      data.id = awardRulesFilterChosenRef.current.length + 1;
      data.type = 2;
      data.value = 0;
      setAwardRulesFilterChosen([...awardRulesFilterChosenRef.current, data]);
      loadAwardRulesList();
    }
    else
    {
      let var_value = value;
      let var_id = awardRulesFilterChosenRef.current.length + 1;
      let var_type = 2;
      setAwardRulesFilterChosen([...awardRulesFilterChosenRef.current, {id:var_id, ...award_rules, type: var_type, value: var_value}]);
      loadAwardRulesList();
    }
  };

  const handleChangeAwardRules = (event, oldData) => {
    const {name,value} = event.target;

    setAwardRulesFilterChosen(
      awardRulesFilterChosenRef.current.map((award_rules) => 
      (award_rules.type !==3 && award_rules.type !== 4?
        award_rules.id === oldData.id?
          oldData.type===2?
          {...award_rules, type: 2, [name]: value}
        :
          {...award_rules, type: 1, [name]: value} 
        :award_rules
      :award_rules)
      )
    );

   loadAwardRulesList();
  }

  function handleChangeAwardRulesValue(newData){
    setNewValue(newData);
  }

  function loadAwardRulesList(){
    let list = awardRules;
    awardRulesFilterChosenRef.current.map(data => {
      if(data.type !== 3 && data.type !== 4)
      {
        list = list.filter(e => e.code !== data.code);
      }
    })
     setAwardRulesList(list);
  }

  const handleSubmit = event => {
    event.preventDefault();

    if(newValue === undefined)
    {
      handleChangeAwardRulesValue(addAwardRules(null));
    }
    else
    {
      handleChangeAwardRulesValue(addAwardRules(newValue, value));
    }
    loadAwardRulesList();
  }

  const updateAwardRules = (newData, oldData) => {  
    setAwardRulesFilterChosen(
      awardRulesFilterChosenRef.current.map((award_rules) => (
        award_rules.type !==3?
          award_rules.id === oldData.id? 
            oldData.type===2?
              {id:oldData.id, ...newData, type: 2, value: oldData.value}
            :
              {id:oldData.id, award_rules_id:oldData.award_rules_id , ...newData, type: 1, value: oldData.value} 
            :award_rules 
        :
        award_rules   
      ))
    );  

   loadAwardRulesList();

  };

  const deleteAwardRules = (award_rules) => {    
    if(award_rules.type===2)
    {
      setAwardRulesFilterChosen(awardRulesFilterChosenRef.current.map((awardrulesdata) => awardrulesdata.id === award_rules.id? 
      {id:award_rules.id, ...award_rules, type: 4, value: award_rules.value} 
      : awardrulesdata)
      ); 
    }
    else
    {
      setAwardRulesFilterChosen(awardRulesFilterChosenRef.current.map((awardrulesdata) => awardrulesdata.id === award_rules.id? 
      {id:award_rules.id, ...award_rules, type: 3, value: award_rules.value} 
      : awardrulesdata)
      ); 
    }
    loadAwardRulesList();
  };

  const LoopAwardRulesData = (Data) => {
    return(
      Data.map((awardRulesData,index) => {
        if(awardRulesData.type!==3 && awardRulesData.type!==4)
        {
          return(
            <List key={index} dense={true} style={{padding:'0px'}}>
              <ListItem>
                <ListItemText primary={
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={9}>
                      <AutoCompleteCustom
                        key={index}
                        size="small"
                        aria-label='Award Rules'
                        options={awardRulesList}
                        getOptionLabel={(option) => option.code? option.code:''}
                        id={`AutoComplete-material-${index}`}
                        onChange={(event, newValue) => {
                          updateAwardRules(newValue, awardRulesData);
                        }}
                        disableClearable
                        renderInput={(params) => 
                          <TextFieldCustom {...params} variant="outlined" label={awardRulesData.code!==null?awardRulesData.code:''}/>
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextFieldCustom name="value" onChange={(event)=>handleChangeAwardRules(event,awardRulesData)} value={awardRulesData.value} variant="outlined" label='Value' size="small" type="number" step="any"/>
                    </Grid>
                  </Grid>
                } />
                <ListItemSecondaryAction>
                  <RemoveCircleIcon 
                    data-testid='remove rules'
                    id="iconButton"
                    className="delete_material_iconButton"
                    color="error"
                    style={{ fontSize: 20 }}
                    onClick={()=>deleteAwardRules(awardRulesData)} 
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          );
        }
        else
        {
          return null;
        }
      })
    );
  }

  return[
    //states
    {
      open, title, Awards,
      mode,  loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      code, name, description, awardRules, awardRulesList,
      awardRulesFilterChosen, payrunFilterChosenRef, payrunRef,
      awardRulesFilterChosenRef, loadingProcessRef
    },
    //function
    {
      setAwardRulesList, handleSubmit,
      setPayrunFilterChosen, setAwardRulesFilterChosen,
      handleClickOpen, handleClose, SaveAwards,
      handleChange, handleChangeInput, pageChange,
      LoopAwardRulesData,
    }
  ];
}

export default AwardsViewModel;