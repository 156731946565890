import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Chip,
  Checkbox,
} from '@material-ui/core';
import {
  SubmitButton,
  CancelButton,
  pageOptions,
  inputTypeOptions,
  CustomTooltip,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  TextFieldCustom2,
  CustomSwitch,
  AutoCompleteCustom,
  FormControlCustom
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import { SessionOptionViewModel } from "../../ViewModel/Settings/SessionOptionViewModel";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import SettingsSkeleton from './SettingsSkeleton';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SessionOption() {
  const [
    //states
    {
      open, title, sessionOptions,
      mode, loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      sort, code, name, question, inputType, criteria,
      defaultValue, afterOvertime, onlyIfOptionSet,
      onlyOvertimeRef, employeeSettingRef, invertOutputRef,
      onlyRosteredDaysRef,
      awardsFilterChosenRef, awards, customersFilterChosenRef, customers,
      sessionTypesFilterChosenRef, sessionTypes,
      hideIfOptionSet, hideIfOptionSetChosenRef, loadingProcessRef
    },
    //function
    {
      setAwardsFilterChosen, setCustomersFilterChosen, setSessionTypesFilterChosen,
      handleClickOpen, handleClose, SaveSessionOptions, setHideIfOptionSetChosen,
      handleChange, handleChangeInput, pageChange
    }
  ] = SessionOptionViewModel();

  const classes = useStylesEmployees();
  /* eslint-enable array-callback-return*/

  const handleClickList = (e, isHaveRelation) => {
    if (!isHaveRelation) {
      e.stopPropagation();
    }
  }

  return (
    <div>
      {sessionOptions ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6', paddingRight: '40px' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={1}>
                      <Typography variant="h5">Code</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Question</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }}>Input Type</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }}>Default Value</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }} >Only Overtime</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center">After Overtime</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                      {localconfiguration.session_options_settings ?
                        <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                          <AddIcon />
                        </AddButton>
                        : ''
                      }
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            <div style={{ marginBottom: '20px' }}>
              {sessionOptions.map((data, index) => {
                const isHaveRelation = data.awards.length > 0 || data.customers.length > 0 || data.session_types.length > 0 || data.hide_if_option_set;
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`session_option_${index}`}
                      expandIcon={isHaveRelation ? <ExpandMoreIcon /> : ''}
                      style={{ userSelect: 'auto', cursor: isHaveRelation ? 'pointer' : 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => handleClickList(event, isHaveRelation)} onFocus={(event) => event.stopPropagation()} style={{ paddingRight: isHaveRelation ? '0px' : '36px' }}>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`sessionOptions_code_${index}`}>{data.code}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`sessionOptions_name_${index}`}>{data.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={4}>
                          <Typography noWrap variant="h6" id={`sessionOptions_description_${index}`}>{data.description}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`sessionOptions_input_type_${index}`} align="center">{data.input_type}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`sessionOptions_default_value_${index}`} align="center">{data.default_value ? data.default_value : '-'}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`sessionOptions_only_overtime_${index}`} align="center">{data.only_overtime ? 'true' : 'false'}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`sessionOptions_after_overtime_${index}`} align="center">{data.after_overtime}</Typography>
                        </GridCustom>
                        <Grid item xs={1} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'edit'); }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {localconfiguration.session_options_settings ?
                            <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'delete'); }} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                            : ''}
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={index} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.awards.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Awards: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  {data.awards.map((awards, index) => {
                                    return (
                                      <CustomTooltip key={index} title={<Typography variant="h6">{awards.awards_id.name}</Typography>}>
                                        <Chip key={index} variant="outlined" size="small" label={
                                          <Typography variant="h6" id={`sp_cus_${index}`}>
                                            {awards.awards_id.code}
                                          </Typography>} color="primary" />
                                      </CustomTooltip>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.customers.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Customers: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  {data.customers.map((customer, index) => {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={
                                        <Typography variant="h6" id={`sp_cus_${index}`}>
                                          {customer.customers_id.name}
                                        </Typography>} color="primary" />
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.session_types.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6"> Session Types: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  {data.session_types.map((session_type, index) => {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={
                                        <Typography variant="h6" id={`sp_cus_${index}`}>
                                          {session_type.session_types_id.name}
                                        </Typography>} color="primary" />
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.hide_if_option_set ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1}>
                                  <CustomTooltip key={index} title={<Typography variant="h6"> Hide If Option Set:</Typography>}>
                                    <Typography noWrap variant="h6"> Hide If Option Set: &nbsp;</Typography>
                                  </CustomTooltip>
                                </Grid>
                                <Grid item xs={9}>
                                  <Chip key={index} variant="outlined" size="small" label={
                                    <Typography variant="h6" id={`sp_cus_${index}`}>
                                      {data.hide_if_option_set.name}
                                    </Typography>} color="primary" />
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="sessionOptions_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
                disableEnforceFocus
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="sessionOptions_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="sort_text"
                          label="Sort"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="sort"
                          value={sort ?? ''}
                          type="number"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      {localconfiguration.session_options_settings ?
                        <>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              size='small'
                              id="code_text"
                              label="Code"
                              variant="outlined"
                              fullWidth={true}
                              margin="normal"
                              name="code"
                              value={code ?? ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              size='small'
                              id="name_text"
                              label="Name"
                              variant="outlined"
                              fullWidth={true}
                              margin="normal"
                              name="name"
                              value={name ?? ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                        </>
                        : ''}
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="question_text"
                          label="Question"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="question"
                          value={question ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlCustom size='small' fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
                          <Select
                            aria-label='Input Type'
                            id="input_type_text"
                            value={inputType}
                            name="input_type"
                            onChange={handleChangeInput}
                          >
                            {inputTypeOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControlCustom>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="criteria_text"
                          label="Criteria"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="criteria"
                          value={criteria ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="default_value_text"
                          label="Default Value"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="default_value"
                          value={defaultValue ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="only_if_option_set_text"
                          label="Only If Option Set"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="only_if_option_set"
                          value={onlyIfOptionSet ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {hideIfOptionSet ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Hide If Option Set'
                            disablePortal
                            id="awards-select"
                            options={hideIfOptionSet}
                            fullWidth={true}
                            getOptionLabel={(option) => option.code + ' - ' + option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setHideIfOptionSetChosen(newValue)
                            }}
                            value={hideIfOptionSetChosenRef.current}
                            renderInput={(params) => (
                              <TextFieldCustom {...params} variant="outlined" label="Hide If Option Set" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }

                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {awards && awardsFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Select Awards'
                            multiple
                            id="awards-select"
                            options={awards}
                            disableCloseOnSelect
                            fullWidth={true}
                            getOptionLabel={(option) => option.code}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code}
                              </React.Fragment>
                            )}
                            value={awardsFilterChosenRef.current}
                            onChange={(event, newValue) => {
                              setAwardsFilterChosen(newValue);
                            }}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select Awards" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {customers && customersFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Select Customers'
                            multiple
                            id="customers-select"
                            options={customers}
                            disableCloseOnSelect
                            fullWidth={true}
                            margin="normal"
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setCustomersFilterChosen(newValue)
                            }}
                            value={customersFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select Customers" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {sessionTypes && sessionTypesFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Select Session Types'
                            multiple
                            id="session-types-select"
                            options={sessionTypes}
                            disableCloseOnSelect
                            fullWidth={true}
                            margin="normal"
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setSessionTypesFilterChosen(newValue)
                            }}
                            value={sessionTypesFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select Session Types" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={5}>
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  margin="normal"
                                  control={
                                    <CustomSwitch name="only_overtime" checked={onlyOvertimeRef.current} onChange={handleChangeInput} />
                                  }
                                  label="Only Overtime"
                                />
                              </FormGroup>
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <TextFieldCustom
                              id="after_overtime_text"
                              label="After Overtime"
                              variant="outlined"
                              fullWidth={true}
                              margin="normal"
                              name="after_overtime"
                              value={onlyOvertimeRef.current ? afterOvertime : 0}
                              onChange={handleChangeInput}
                              type="number"
                              size="small"
                              disabled={onlyOvertimeRef.current ? false : true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              margin="normal"
                              control={
                                <CustomSwitch name="employee_setting" checked={employeeSettingRef.current} onChange={handleChangeInput} />
                              }
                              label="Employee Setting"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              margin="normal"
                              control={
                                <CustomSwitch name="invert_output" checked={invertOutputRef.current} onChange={handleChangeInput} />
                              }
                              label="Invert Output"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              margin="normal"
                              control={
                                <CustomSwitch name="only_rostered_days" checked={onlyRosteredDaysRef.current} onChange={handleChangeInput} />
                              }
                              label="Only Rostered Days"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                    </Grid>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <SettingsSkeleton status='saveandcancel_load' />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveSessionOptions()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }

                </DialogActions>
              </Dialog>
            </div>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {sessionOptions ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            aria-label='Back Button'
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            aria-label='Next Button'
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}