import React, { useState, useEffect } from 'react';
import { ResourcesController, ResourceTypeListController, ResourceUnitListController} from '../../Controller/Settings/Resources.js';
import directus from '../../services/directus.js';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage.js';

let page = 1;

export const ResourcesViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [Resources, setResources] = useState(null);
  const [searchResource, setSearchResource] = useState('');

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [loadingProcess, setLoadingProcess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [type, setType] = React.useState('');
  const [code, setCode] = React.useState('');
  const [companyCode, setCompanyCode] = React.useState('');
  const [units, setUnits] = useState('');
  const [UnitValue, setUnitValue] = React.useState('');
  const [vehicle, setVehicle] = useState(false);
  const [fuelPump, setFuelPump] = useState(false);
  
  const [resourceData, setResourceData] = useState(null);
  const [resourceTypeList, setResourceTypeList] = useState(null);  
  const [resourceUnitList, setResourceUnitList] = useState(null);
  
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(localStorage.getItem("resources_page") ? localStorage.getItem("resources_page") : 50);


  const { enqueueSnackbar } = useSnackbar();

  /* eslint-enable no-unused-vars*/
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadResources();
    }

    const searchResource = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchResource(eventData.text);
      loadResources(eventData.text);
    }

    const listener = EventEmitter.addListener('searchResource', searchResource);

    return () => {
      listener.remove();
    }
  });


  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = name === '';

  const loadResources = async (data) => {
    var ResourcesResults = await ResourcesController(data, page);
    setResourceTypeList(await ResourceTypeListController());
    setResourceUnitList(await ResourceUnitListController());
    
    setTotalPage(ResourcesResults.meta.filter_count);
    setResources(ResourcesResults.data);    
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadResources(searchResource);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    page = 1;
    setPageNumber(event.target.value);
    localStorage.setItem("resources_page", JSON.parse(event.target.value));
    loadResources(searchResource);
  };

  const handleClickOpen = (resource, mode) => {
    setOpen(true);
    if (mode === 'edit') {
      setName(resource.name);
      setDescription(resource.description);

      if (resource.type === 'chemical') {
        setType({ id: 0, name: 'Material', value: resource.type });
      }
      else {
        setType({ id: 0, name: resource.type, value: resource.type });
      }

      setUnits(resource.units?resource.units:'');
      setUnitValue(resource.units?resource.units:'');
      setCode(resource.code);
      setCompanyCode(resource.company_code);
      setVehicle(resource.vehicle);
      setFuelPump(resource.fuel_pump);
      setResourceData(resource);
      setMode('edit');
      setTitle('Edit Resource');
      setText('');
    }
    else if (mode === 'add') {
      setName('');
      setDescription('');
      setType({ id: 0, name: 'Equipment', value: 'equipment' });
      setUnits('');
      setUnitValue(resourceUnitList[0]);
      setCode('');
      setCompanyCode('');
      setVehicle(false);
      setFuelPump(false);
      setMode('add');
      setTitle('Add Resource');
      setText('');
    }
    else {
      setResourceData(resource);
      setMode('delete');
      setTitle('Delete Resource');
      setText('Are you Sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
    }
    if (name === 'code') {
      setCode(value);
    }
    if (name === 'company_code') {
      setCompanyCode(value);
    }
    if (name === 'description') {
      setDescription(value);
    }
    if (name === 'vehicle'){
      setVehicle(e.target.checked);
    }
    if (name === 'fuel_pump'){
      setFuelPump(e.target.checked);
    }
  }

  const SaveResources = async () => {
    setLoading(true);
    if (mode === 'edit') {
      try {
        await directus.updateItem('resources', resourceData.id, {
          name: name ? name : '',
          description: description ? description : '',
          type: type.value ? type.value : '',
          code: code ? code : '',
          company_code: companyCode ? companyCode : '',
          units: units ? units : '',
          vehicle: vehicle?vehicle:'',
          fuel_pump:fuelPump?fuelPump:'',          
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else if (mode === 'add') {
      try {
        await directus.createItem('resources', {
          name: name,
          description: description ? description : '',
          type: type.value ? type.value : '',
          code: code ? code : '',
          company_code: companyCode ? companyCode : '',
          units: units ? units : '',
          vehicle: vehicle?vehicle:'',
          fuel_pump:fuelPump?fuelPump:'',     
          status: 'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {
      try {
        await directus.deleteItem('resources', resourceData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    loadResources();
    setLoading(false);
    handleClose();
  }

  return [
    //states
    {
      title, text, open, disable, type, code,
      Resources, name, description, companyCode,
      localconfiguration, mode, loading,
      pageNumber, pageNumberRef, totalPage,
      page, loadingProcess, resourceTypeList,
      resourceUnitList, units, UnitValue,
      vehicle,fuelPump,
    },
    //function
    {
      SaveResources, handleClickOpen, handleClose,
      handleChangeInput, pageChange, handleChange,
      setType, setUnits, setUnitValue,
    },
  ];
}

export default ResourcesViewModel;