import directus from '../../services/directus';

export const ResourcesController = async (data,page) => {
  let filterData;

  if(data)
  {
    filterData = {
      fields: 'id,status,type.*,name,units,description,code,company_code,vehicle,fuel_pump',
      meta: "filter_count",
      limit: localStorage.getItem("resources_page")?localStorage.getItem("resources_page"):50,
      page: page,
      filter: {
        'name': { like: data },
        'status': "published"
      },
      sort:'name',
    }
  }
  else
  {
    filterData = {
      fields: 'id,status,type.*,name,units,description,code,company_code,vehicle,fuel_pump',
      filter: { 
        status : { eq: 'published' },
      },
      meta: "filter_count",
      limit: localStorage.getItem("resources_page")?localStorage.getItem("resources_page"):50,
      page: page,
      sort:'name'
    }; 
  }
    var result = await directus.getItems('resources', filterData);

    return result;
};

export const ResourceTypeListController = async () => {
  let typeList = [];
  var type_data =  await directus.getField("resources", "type");
  const type_list = Object.entries(type_data.data.options.choices);
  type_list.map((data, index) => {
    typeList[index] = { id: data[0], name: data[1], value: data[0] };
  })  
  return typeList;
};

export const ResourceUnitListController = async () => {
  let unitList = [];
  var unit_data =  await directus.getField("resources", "units");
  const unit_list = Object.entries(unit_data.data.options.choices);
  unit_list.map((data, index) => {
    unitList[index] = data[0];
  })  
  unitList.push("Others");

  return unitList;
};
