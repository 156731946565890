import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  useStyles,
  CustomCardContent,
  CustomGridApproval,
  pageOptions,
  buttonLeftSX,
  buttonRightSX,
} from './style.js';
import Page from 'src/components/Page';
import { enableRipple } from '@syncfusion/ej2-base';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import useStateRef from "react-usestateref";
import directus from '../../services/directus.js';
import ReportingSessionList from './ReportingSessionList.js';
import EventEmitter from 'src/utils/EventEmitter';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage.js';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';

let filters_reporting;

const Reporting = () => {
  /* eslint-disable */
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [searchApproval, setSearchApprovals, SearchApprovalRef] = useStateRef('');
  const [approvalLockData, setApprovalLockData, approvalLockDataRef] = useStateRef('');
  const [lockLoading, setLockLoading] = React.useState(false);
  const [exportdata, setExportData] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(5);
  const [selectedView, setSelectedView] = React.useState(1);
  const [errorText, setErrorText] = React.useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [description, setDescription, descriptionRef] = useStateRef('');
  const [notification, setNotification, notificationRef] = useStateRef('');
  const [title, setTitle, titleRef] = useStateRef('');
  const [statusValue, setStatusValue] = useState(null);
  const [codeAll, setCodeAll] = useState(false);
  const [type, setType] = React.useState([]);
  const [role, setRole] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const [sessionLoading, setSessionLoading] = useState(false);
  const [pageLoadingLeft, setPageLoadingLeft] = React.useState(false);
  const [pageLoadingRight, setPageLoadingRight] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(25);
  const [sessionData, setSessionData, sessionDataRef] = useStateRef(null);

  const [startDate, setStartDate, startDateRef] = useStateRef(null);
  const [endDate, setEndDate, endDateRef] = useStateRef(null);
  const [employeesData, setEmployeesData, employeesDataRef] = useStateRef([]);
  const [sessionStatus, setSessionStatus, sessionStatusRef] = useStateRef([]);
  const [customersData, setCustomersData, customersDataRef] = useStateRef([]);
  const [activitiesData, setActivitiesData, activitiesDataRef] = useStateRef([]);
  const [materialsData, setMaterialsData, materialsDataRef] = useStateRef([]);
  const [equipmentsData, setEquipmentsData, equipmentsDataRef] = useStateRef([]);
  const [sessionTypesData, setSessionTypesData, sessionTypesDataRef] = useStateRef([]);

  filters_reporting = JSON.parse(window.localStorage.getItem('filters_reporting'));


  /* eslint-enable */
  enableRipple(true);

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      statusSummaryTypeLoad();

      if (filters_reporting) {
        setStartDate(filters_reporting.startDateRange);
        setEndDate(filters_reporting.endDateRange);
        setEmployeesData(filters_reporting.employeesList.map(data => data.id));
        setSessionStatus(filters_reporting.timesheetStatus.map(data => data.value));
        setCustomersData(filters_reporting.customersList.map(data => data.id));
        setActivitiesData(filters_reporting.activitiesList.map(data => data.id));
        setMaterialsData(filters_reporting.materialsList.map(data => data.id));
        setEquipmentsData(filters_reporting.equipmentsList.map(data => data.id));
        setSessionTypesData(filters_reporting.sessionTypesList.map(data => data.id));
        setPage(filters_reporting.page);
        loadSession();
      }
      else {
        loadSession();
      }
    }

    const searchApprovals = (eventData) => {
      setSearchApprovals(eventData.text);
    }

    const FiltersReporting = (eventData) => {
      setStartDate(eventData.startDateRange);
      setEndDate(eventData.endDateRange);
      setEmployeesData(eventData.employeesList);
      setSessionStatus(eventData.timesheetStatus);
      setCustomersData(eventData.customersList);
      setActivitiesData(eventData.activitiesList);
      setMaterialsData(eventData.materialsList);
      setEquipmentsData(eventData.equipmentsList);
      setSessionTypesData(eventData.sessionTypesList);
      setPage(eventData.page);
      loadSession();
    }

    const listener = EventEmitter.addListener('searchApprovals', searchApprovals);
    const listenerFilterTimesheet = EventEmitter.addListener('FiltersReporting', FiltersReporting);
    
    return () => {
      listener.remove();
      listenerFilterTimesheet.remove();
      setInitialized(false);
    }
  }, []);


  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      const statusMappings = {
        approved: { name: "Approved" },
        rejected: { name: "Rejected" },
        pending: { name: "Not Yet Submitted" },
        awaiting_approval: { name: "Waiting Approval" },
        sent: { name: "Sent to Payroll" },
        paid: { name: "Paid" }
      };

      const timesheet_list = timesheet_status.map(([, value]) => ({
        value: value.value,
        ...statusMappings[value.value],
      }));

      setTimesheetStatusType(timesheet_list);
    }

    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const loadSession = async () => {
    try {
      setSessionLoading(true);
      var SessionResult = await directus.api.post('/custom/reports/sessions', {
        "start_date": startDateRef.current ? startDateRef.current : null,
        "end_date": endDateRef.current ? endDateRef.current : null,
        "employees": employeesDataRef.current,
        "status": sessionStatusRef.current,
        "customers": customersDataRef.current,
        "activities": activitiesDataRef.current,
        "materials": materialsDataRef.current,
        "equipments": equipmentsDataRef.current,
        "session_types": sessionTypesDataRef.current,
        "page": page,
        "limit": pageNumberRef.current,
        "sort": "session_date",
      });

      setSessionData(SessionResult.data);
      setTotalPage(SessionResult.meta.filter_count);
      setPageLoadingLeft(false);
      setPageLoadingRight(false);
      setSessionLoading(false);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      setSessionLoading(false);
    }
  }

  const pageChange = (value) => {
    if (value === 1) {
      setPageLoadingRight(true);
    }
    else {
      setPageLoadingLeft(true);
    }

    setPage((prev) => prev + value);

    loadSession();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    loadSession();
    setPage(1);

  };

  return (
    <Page className={classes.root} title="Reporting">
      <Container maxWidth={false}>
        <Grid container>
          <Grid
            item xs={12}
            style={{ textAlign: 'right' }}
          >
            <span style={{ paddingRight: '20px' }}>
              Rows per page:
            </span>
            <span style={{ paddingRight: '20px' }}>
              <TextField
                id="standard-select-currency"
                select
                value={pageNumberRef.current}
                onChange={handleChange}
              >
                {pageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </span>

            <span id="page_number" style={{ paddingRight: '20px' }}>
              {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
            </span>

            <LoadingButton
              id="prev_btn"
              variant="outlined"
              size="small"
              disabled={page === 1}
              onClick={() => pageChange(-1)}
              loading={pageLoadingLeft}
              sx={buttonLeftSX}
            >
              <ArrowBackIosRoundedIcon />
            </LoadingButton>

            <LoadingButton
              id="next_btn"
              variant="outlined"
              size="small"
              disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
              onClick={() => pageChange(1)}
              loading={pageLoadingRight}
              sx={buttonRightSX}
            >
              <ArrowForwardIosRoundedIcon />
            </LoadingButton>
          </Grid>
        </Grid>

        <div className="control-pane">
          <div className="control-section">
            <Card elevation={0} className={classes.card}>
              <CustomCardContent className={classes.cardContent}>
                {sessionDataRef.current ?
                  <Grid container spacing={0}>
                    <CustomGridApproval item xs={1} style={{ paddingLeft: '20px' }}>
                      <Typography><b>Date</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1}>
                      <Typography style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}><b>Type</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2}>
                      <Typography style={{ whiteSpace: 'nowrap', paddingLeft: "20px" }}><b>Employee</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1}>
                      <Typography style={{ whiteSpace: 'nowrap' }}><b>Customer</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2}>
                      <Typography style={{ whiteSpace: 'nowrap' }} ><b>Activity</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1}>
                      <Typography style={{ whiteSpace: 'nowrap' }}><b>Start</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1}>
                      <Typography style={{ whiteSpace: 'nowrap' }}><b>End</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2} style={{ paddingLeft: '9px' }}>
                      <Typography style={{ whiteSpace: 'nowrap' }}><b>Status</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1}>
                      <Typography style={{ whiteSpace: 'nowrap' }}><b>Duration</b></Typography>
                    </CustomGridApproval>
                  </Grid>
                  :
                  <Grid container spacing={0}>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap', textAlign: 'right' }} item xs={1}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                  </Grid>
                }
              </CustomCardContent>
            </Card>


            {sessionDataRef.current ?
              sessionDataRef.current.length > 0 ?
                sessionLoading ?
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                    </Grid>
                  </Grid>
                  :
                  <>
                    <ReportingSessionList
                      sessions={sessionDataRef.current}
                      timesheetStatusType={timesheetStatusType}
                    />
                  </>
                :
                !moveLoading ?
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ opacity: '0.2', paddingTop: '6pc' }}
                  >
                    <Grid item xs={12} sm={12} lg={12}>
                      <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                      <Typography variant="h3" style={{ textAlign: 'end' }}>No Data Found</Typography>
                    </Grid>

                  </Grid>
                  : ''
              :
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
              </Grid>
            }
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Reporting;