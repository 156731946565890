import React, { useEffect } from 'react';
import { Box, List, makeStyles, Typography, } from '@material-ui/core';
import directus from '../../../services/directus';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import EventEmitter from 'src/utils/EventEmitter';
import { DateRangePicker } from "mui-daterange-picker";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import useStateRef from "react-usestateref";
import {
  ApplyButton,
  ExportButton,
  FilterButton,
} from '../style.js';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  TextFieldDateRangeCustom,
  TextFieldFilterTimesheetCustom,
  CheckboxCustom,
  AutocompleteFilterTimesheetCustom,
} from './style.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: '300px',
    top: '80px',
    backgroundColor: 'white',
    borderRight: '0px',
    zIndex: 'auto'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px'
  },
  box: {
    padding: '0 0 0 26px',
  },
  chipcustom: {
    height: '20px',
    width: 'auto',
    marginTop: '3px',
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
      paddingTop: '1px'
    }
  },
  circularSkeletonCustom: {
    height: '20px',
    width: '20px',
    marginTop: '4px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '1px'
  },
  cardCustom: {
    margin: '0px 10px 10px 16px',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    '& .MuiCardContent-root': {
      padding: '6px 0px 0px 0px'
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px !important'
    }
  },
  tabstyle: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20.49px'
  },
  fontstyle1: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.39px'
  },
  fontstyle2: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '13.66px'
  },
  fontstyle3: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '12.99px'
  },
  fontstyle4: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '13.66px',
    textAlign: 'right'
  },
  fontstyle5: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16.39px',
    fontFamily: 'Manrope'
  },
  DatePickerCustom: {
    display: 'flex !important',
    height: '24px',
    marginBottom: '10px',
    '& .p-inputtext': {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& .p-datepicker-calendar': {
      fontSize: '12px',
      padding: '0px !important',
    },

  },
  DateRangePickerCustom: {
    "& > *": {
      boxShadow: 'none !important',
    },
  },
  DialogCustom: {
    "& .MuiPaper-root": {
      maxWidth: '690px !important',
    },
  }
}));

let filters_reporting;

const FiltersReporting = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = React.useState(false);

  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [startDateChosen, setStartDateChosen] = React.useState('');
  const [endDateChosen, setEndDateChosen] = React.useState('');
  const [dateRange, setDateRange] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggle = () => setOpen(!openDialog);

  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [timesheetStatus, setTimesheetStatus] = React.useState([]);

  const [employees, setEmployees] = React.useState([]);
  const [employeesChosen, setEmployeesChosen] = React.useState([]);
  const [employeesLoading, setEmployeesLoading] = React.useState(false);

  const [customers, setCustomers] = React.useState([]);
  const [customersChosen, setCustomersChosen] = React.useState([]);
  const [customersLoading, setCustomersLoading] = React.useState(false);

  const [activities, setActivities] = React.useState([]);
  const [activitiesChosen, setActivitiesChosen] = React.useState([]);
  const [activitiesLoading, setActivitiesLoading] = React.useState(false);

  const [materials, setMaterials] = React.useState([]);
  const [materialsChosen, setMaterialsChosen] = React.useState([]);
  const [materialsLoading, setMaterialsLoading] = React.useState(false);

  const [equipments, setEquipments] = React.useState([]);
  const [equipmentsChosen, setEquipmentsChosen] = React.useState([]);
  const [equipmentsLoading, setEquipmentsLoading] = React.useState(false);

  const [sessionTypes, setSessionTypes] = React.useState([]);
  const [sessionTypesChosen, setSessionTypesChosen] = React.useState([]);
  const [sessionTypesLoading, setSessionTypesLoading] = React.useState(false);

  const [exportLoading, setExportLoading] = React.useState(null);
  const [open, setOpen] = React.useState('');

  filters_reporting = JSON.parse(window.localStorage.getItem('filters_reporting'));

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      employeesLoad();
      statusSummaryTypeLoad();
      customersLoad();
      activitiesLoad();
      materialsLoad();
      equipmentsLoad();
      sessionTypesLoad();

      if (filters_reporting) {
        setStartDateChosen(filters_reporting.startDateRange ? dayjs(filters_reporting.startDateRange).format('YYYY-MM-DD') : '');
        setEndDateChosen(filters_reporting.endDateRange ? dayjs(filters_reporting.endDateRange).format('YYYY-MM-DD') : '');
        setEmployeesChosen(filters_reporting.employeesList);
        setTimesheetStatus(filters_reporting.timesheetStatus);
        setCustomersChosen(filters_reporting.customersList);
        setActivitiesChosen(filters_reporting.activitiesList);
        setMaterialsChosen(filters_reporting.materialsList);
        setEquipmentsChosen(filters_reporting.equipmentsList);
        setSessionTypesChosen(filters_reporting.sessionTypesList);
      }
    }

    return () => {
      setInitialized(false);
    }
  }, []);

  const ExportReporting = () => {
    setExportLoading(true);

    let dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/reports/sessions?export=true`;
    // Define the request body data
    const requestData = {
      start_date: startDateChosen,
      end_date: endDateChosen,
      employees: employeesChosen.map(data => data.id),
      status: timesheetStatus.map(data => data.value),
      customers: customersChosen.map(data => data.id),
      activities: activitiesChosen.map(data => data.id),
      materials: materialsChosen.map(data => data.id),
      equipments: equipmentsChosen.map(data => data.id),
      session_types: sessionTypesChosen.map(data => data.id),
      sort: 'session_date',
    };

    // Define the fetch options
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + directus.api.auth.config.token
      },
      body: JSON.stringify(requestData), // Convert the request data to JSON format
    };

    // Make the POST request
    fetch(dataURL, fetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); // Extract blob data from the response
      })
      .then(blobData => {
        setExportLoading(false); 
        // If you want to download the blob data as a file, you can create a download link
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'exported_data.xlsx'; // You can specify the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        // Handle any errors that may occur during the fetch request
        console.error('Fetch Error:', error);
        setExportLoading(false);
      });

  }

  const FiltersReporting = () => {
    EventEmitter.emit('FiltersReporting', {
      startDateRange: startDateChosen,
      endDateRange: endDateChosen,
      employeesList: employeesChosen.map(data => data.id),
      timesheetStatus: timesheetStatus.map(data => data.value),
      customersList: customersChosen.map(data => data.id),
      activitiesList: activitiesChosen.map(data => data.id),
      materialsList: materialsChosen.map(data => data.id),
      equipmentsList: equipmentsChosen.map(data => data.id),
      sessionTypesList: sessionTypesChosen.map(data => data.id),
      page: 1,
    })

    window.localStorage.setItem('filters_reporting',
      JSON.stringify({
        startDateRange: startDateChosen,
        endDateRange: endDateChosen,
        employeesList: employeesChosen,
        timesheetStatus: timesheetStatus,
        customersList: customersChosen,
        activitiesList: activitiesChosen,
        materialsList: materialsChosen,
        equipmentsList: equipmentsChosen,
        sessionTypesList: sessionTypesChosen,
        page: 1,
      }));
  }

  const resetFilters = () => {
    setStartDateChosen('');
    setEndDateChosen('');
    setEmployeesChosen([]);
    setTimesheetStatus([]);
    setCustomersChosen([]);
    setActivitiesChosen([]);
    setMaterialsChosen([]);
    setEquipmentsChosen([]);
    setSessionTypesChosen([]);
  };

  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      const nameMappings = {
        pending: "Not Yet Submitted",
        awaiting_approval: "Waiting Approval",
        sent: "Sent to Payroll"
      };

      const timesheet_list = timesheet_status
        .filter(([, value]) => value.value !== "deleted")
        .map(([value, valueData]) => ({
          value: value,
          name: nameMappings[valueData.value] || valueData.name
        }));
      setTimesheetStatusType(timesheet_list);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  function handleChangeTimesheetStatus(value) {
    setTimesheetStatus(value);
  }

  const employeesLoad = async () => {
    setEmployeesLoading(true);
    var employeesData = await directus.getItems('employees', {
      filter: { status: { eq: 'published' } },
      fields: 'id,code,user.first_name,user.last_name',
      sort: 'code',
      limit: -1
    });
    setEmployees(employeesData.data);
    setEmployeesLoading(false);
  }

  function handleChangeEmployees(value) {
    setEmployeesChosen(value);
  }

  const customersLoad = async () => {
    setCustomersLoading(true);
    var customersData = await directus.getItems('customers', {
      filter: { status: { eq: 'published' } },
      fields: 'id,name',
      sort: 'name',
      limit: -1
    });
    setCustomers(customersData.data);
    setCustomersLoading(false);
  }

  function handleChangeCustomers(value) {
    setCustomersChosen(value);
  }

  const activitiesLoad = async () => {
    setActivitiesLoading(true);
    var activitiesData = await directus.getItems('activities', {
      filter: { status: { eq: 'published' } },
      fields: 'id,name',
      sort: 'name',
      limit: -1
    });
    setActivities(activitiesData.data);
    setActivitiesLoading(false);
  }

  function handleChangeActivities(value) {
    setActivitiesChosen(value);
  }

  const materialsLoad = async () => {
    setMaterialsLoading(true);
    var materialsData = await directus.getItems('resources', {
      fields: 'id,code,name',
      filter: {
        type: { eq: 'chemical' },
        status: { eq: 'published' }
      },
      limit: -1,
      sort: "name"
    });

    setMaterials(materialsData.data);
    setMaterialsLoading(false);
  }

  function handleChangeMaterials(value) {
    setMaterialsChosen(value);
  }

  const equipmentsLoad = async () => {
    setEquipmentsLoading(true);
    var equipmentsData = await directus.getItems('resources', {
      fields: 'id,code,name',
      filter: {
        type: { eq: 'equipment' },
        status: { eq: 'published' }
      },
      limit: -1,
      sort: "name"
    });

    setEquipments(equipmentsData.data);
    setEquipmentsLoading(false);
  }

  function handleChangeEquipments(value) {
    setEquipmentsChosen(value);
  }

  const sessionTypesLoad = async () => {
    setSessionTypesLoading(true);
    var sessionTypesData = await directus.getItems('session_types', {
      fields: 'id,name',
      filter: {
        status: { eq: 'published' }
      },
      limit: -1,
      sort: "name"
    });

    setSessionTypes(sessionTypesData.data);
    setSessionTypesLoading(false);
  }

  function handleChangeSessionTypes(value) {
    setSessionTypesChosen(value);
  }

  function handleChangeDates(value) {
    setDateRange(value);
    setStartDate(dayjs(value.startDate).format('YYYY-MM-DD'));
    setEndDate(dayjs(value.endDate).format('YYYY-MM-DD'));
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setStartDate('');
    setEndDate('');
  };

  const handleSave = () => {
    setOpenDialog(false);

    if (dateRange !== null) {
      setStartDateChosen(dayjs(dateRange.startDate).format('YYYY-MM-DD'));
      setEndDateChosen(dayjs(dateRange.endDate).format('YYYY-MM-DD'));
    }
    else {
      setStartDateChosen(dayjs(startDateRef.current).format('YYYY-MM-DD'));
      setEndDateChosen(dayjs(endDateRef.current).format('YYYY-MM-DD'));
    }
  };

  const handleDelete = () => {
    setOpenDialog(false);
    setStartDateChosen('');
    setEndDateChosen('');
    setDateRange('');
    setStartDate('');
    setEndDate('');
  };

  return (
    <Box className={classes.List}>
      <List>
        <ListItem>
          <ListItemText className={classes.tabstyle}> <b>Filters Options</b></ListItemText>
        </ListItem>
        <Divider style={{ margin: '0px 16px 14px 16px' }} />
        <Card className={classes.cardCustom}>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0.5}
            >
              <Grid item xs={12}>
                <Typography className={classes.fontstyle5}>Date</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextFieldDateRangeCustom
                  id="FilterDate"
                  variant="outlined"
                  onClick={handleClickOpen}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <>
                        {startDateChosen && endDateChosen ?
                          <Chip
                            label={'Start: ' + startDateChosen + ' , ' + 'End: ' + endDateChosen}
                            size="small"
                            onDelete={handleDelete}
                          />
                          : ''}
                      </>
                    )
                  }}
                />
                <Dialog
                  onClose={handleClose}
                  open={openDialog}
                  fullWidth={true}
                  maxWidth={'md'}
                  className={classes.DialogCustom}
                >
                  <DateRangePicker
                    closeOnClickOutside
                    open={true}
                    initialDateRange={dateRange}
                    toggle={toggle}
                    wrapperClassName={classes.DateRangePickerCustom}
                    onChange={(range) => handleChangeDates(range)}
                  />
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              <Grid item xs={12}><Typography className={classes.fontstyle5}>Employee</Typography></Grid>
              <Grid item xs={12}>
                <AutocompleteFilterTimesheetCustom
                  multiple
                  id="filterEmployee"
                  options={employees}
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.user ? option.code + ' ' + option.user.first_name + ' ' + option.user.last_name : ''}
                  onChange={(event, newValue) => {
                    handleChangeEmployees(newValue);
                  }}
                  value={employeesChosen}
                  loading={employeesLoading}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <Typography className={classes.fontstyle3}>
                      <CheckboxCustom
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                        {option.code ? option.code : ''}
                        {option.code && option.user ? ' - ' : ''}
                        {option.user ? option.user.first_name ? option.user.first_name + ' ' : '' : ''}
                        {option.user ? option.user.last_name ? option.user.last_name : '' : ''}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextFieldFilterTimesheetCustom {...params} />
                  )}
                />
              </Grid>

              <Grid item xs={12}><Typography className={classes.fontstyle5}>Status</Typography></Grid>
              <Grid item xs={12}>
                <AutocompleteFilterTimesheetCustom
                  multiple
                  id="filterStatus"
                  options={timesheetStatusType}
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.name || ''}
                  onChange={(event, newValue) => {
                    handleChangeTimesheetStatus(newValue);
                  }}
                  value={timesheetStatus}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <Typography className={classes.fontstyle3}>
                      <CheckboxCustom
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextFieldFilterTimesheetCustom {...params} />
                  )}
                />
              </Grid>

              <Grid item xs={12}><Typography className={classes.fontstyle5}>Customer</Typography></Grid>
              <Grid item xs={12}>
                <AutocompleteFilterTimesheetCustom
                  multiple
                  id="filterCustomer"
                  options={customers}
                  size="small"
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name || ''}
                  onChange={(event, newValue) => {
                    handleChangeCustomers(newValue);
                  }}
                  value={customersChosen}
                  loading={customersLoading}
                  renderOption={(option, { selected }) => (
                    <Typography className={classes.fontstyle3}>
                      <CheckboxCustom
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextFieldFilterTimesheetCustom {...params} />
                  )}
                />
              </Grid>

              {activities.length>0?
                <>
                <Grid item xs={12}><Typography className={classes.fontstyle5}>Activity</Typography></Grid>
                <Grid item xs={12}>
                  <AutocompleteFilterTimesheetCustom
                    multiple
                    id="filterActivity"
                    options={activities}
                    size="small"
                    disableCloseOnSelect
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, newValue) => {
                      handleChangeActivities(newValue);
                    }}
                    value={activitiesChosen}
                    loading={activitiesLoading}
                    renderOption={(option, { selected }) => (
                      <Typography className={classes.fontstyle3}>
                        <CheckboxCustom
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextFieldFilterTimesheetCustom {...params} />
                    )}
                  />
                </Grid>
                </>
              :''}
            
              {materials.length >0?
                <>
                  <Grid item xs={12}><Typography className={classes.fontstyle5}>Material</Typography></Grid>
                  <Grid item xs={12}>
                    <AutocompleteFilterTimesheetCustom
                      multiple
                      id="filterMaterial"
                      options={materials}
                      size="small"
                      disableCloseOnSelect
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, newValue) => {
                        handleChangeMaterials(newValue);
                      }}
                      value={materialsChosen}
                      loading={materialsLoading}
                      renderOption={(option, { selected }) => (
                        <Typography className={classes.fontstyle3}>
                          <CheckboxCustom
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextFieldFilterTimesheetCustom {...params} />
                      )}
                    />
                  </Grid>
                </>
              :''}
              
              {equipments.length>0?
                <>
                <Grid item xs={12}><Typography className={classes.fontstyle5}>Equipment</Typography></Grid>
                <Grid item xs={12}>
                  <AutocompleteFilterTimesheetCustom
                    multiple
                    id="filterEquipment"
                    options={equipments}
                    size="small"
                    disableCloseOnSelect
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, newValue) => {
                      handleChangeEquipments(newValue);
                    }}
                    value={equipmentsChosen}
                    loading={equipmentsLoading}
                    renderOption={(option, { selected }) => (
                      <Typography className={classes.fontstyle3}>
                        <CheckboxCustom
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextFieldFilterTimesheetCustom {...params} />
                    )}
                  />
                </Grid>
                </>              
              :''}
              
              <Grid item xs={12}><Typography className={classes.fontstyle5}>Session Type</Typography></Grid>
              <Grid item xs={12}>
                <AutocompleteFilterTimesheetCustom
                  multiple
                  id="filterSessionType"
                  options={sessionTypes}
                  size="small"
                  disableCloseOnSelect
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name || ''}
                  onChange={(event, newValue) => {
                    handleChangeSessionTypes(newValue);
                  }}
                  value={sessionTypesChosen}
                  loading={sessionTypesLoading}
                  renderOption={(option, { selected }) => (
                    <Typography className={classes.fontstyle3}>
                      <CheckboxCustom
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextFieldFilterTimesheetCustom {...params} />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <ApplyButton id="FiltersReportingButton" variant="contained" fullWidth onClick={() => FiltersReporting()}>
                  <Typography className={classes.fontstyle1}>Filter</Typography>
                </ApplyButton>
              </Grid>
              <Grid item xs={12}>
                <FilterButton id="resetFilterButton" variant="contained" fullWidth onClick={() => resetFilters()}>
                  <Typography className={classes.fontstyle1}>Reset Filter</Typography>
                </FilterButton>
              </Grid>
              <Grid item xs={12}>
                <ExportButton id="ExportReportingButton" loading={exportLoading} variant="contained" fullWidth onClick={() => ExportReporting()}>
                  <Typography className={classes.fontstyle1}>Download Result</Typography>
                </ExportButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </List>
    </Box>
  );
};

export default FiltersReporting;
