import { 
  withStyles, 
  Button, 
  makeStyles,
  Chip, 
  Tooltip,
  Switch,
  CardContent,
  Grid,
} from '@material-ui/core';
import Checkbox from "@mui/material/Checkbox";

export const pageOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    marginRight:'8px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const ActionButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FA9917',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#d8820f',
    },
  },
})(Button);

export const CustomChip = withStyles(() => ({
  root: {
    backgroundColor:'white',
    color:'black',
    position:'absolute',
    left:'4%',
    height: '20px',
    width: '20px',
    '& .MuiChip-label':{
      overflow:'initial',
    },
  },
}))(Chip);

export const LeaveSupervisorChip = withStyles(() => ({
  root: {
    backgroundColor:'#FA9917',
    height:'20px',
    width: '20px',
    border:'0px',
    color: '#FA9917',
    '&:hover': {
      backgroundColor: '#FA9917',
    },
    paddingLeft: '12px',
    marginRight:'10px',
  },
}))(Chip);

export const ReviewButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#5DB6FF',
    color: '#ffffff',
    height: '25px',
    width: '100px',
    maxWidth:'-webkit-fill-available',
    marginRight:'5px',
    textTransform:'Capitalize !important',
    '&:hover': {
      backgroundColor: '#4991cc',
    },
  },
})(Button);


export const TooltipCustom = withStyles({
  tooltip: {
    color: "#FFFFFF",
    backgroundColor: "#000000"
  }
})(Tooltip);

export const LockButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#ffb41e',
    marginRight: '5px',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#faac11',
    },
  },
}))(Button);

export const ColorButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    height:'25px',
    backgroundColor: ' #3399FF',
    color: '#ffffff',
    textTransform:'capitalize',
    '&:hover': {
      backgroundColor: '#1E90FF',
    },
  },
}))(Button);

export const ApprovedPaidButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2AC940',
    },
  },
}))(Button);

export const YesPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
}))(Button);

export const NoPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
}))(Button);

export const ApprovePaidCheckbox = withStyles(() => ({
  root:{
    // color: '#2AC940 !important',
    '&. MuiCheckbox-indeterminate': {
      color: "#2AC940 !important"
    },
  }
}))(Checkbox);

export const CodeCheckbox = withStyles(() => ({
  root:{
    padding:'0px 3px 0px 0px !important'
  }
}))(Checkbox);


export const CustomSwitch = withStyles(() => ({
  root:{
    width: 32,
    height: 20,
    padding: 0,
    marginRight:'10px',
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
  
        "& + .MuiSwitch-track": {
          backgroundColor: "white",
          opacity: 1,
          boxSizing: "border-box",
          border: "1px solid rgba(0, 0, 0, 0.1)"
        }
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      margin: "3px 2px 3px 0px",
      color: "#5DB6FF"
    },
    "& .MuiSwitch-track": {
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      backgroundColor: "#958d8d",
      padding: 0
    }
  }
}))(Switch);


export const CustomCardContent = withStyles(() => ({
  root: {
    padding:'0px !important',
  },
}))(CardContent);


export const CustomGrid = withStyles(() => ({
  root: {
    height: '35px',
    alignItems: 'center',
  },
}))(Grid);


export const CustomGridApproval = withStyles(() => ({
  root: {
    height:'35px',
    paddingTop:'10px',
    marginBottom:'10px'
  },
}))(Grid);

export const buttonLeftSX = {
  "&:hover": {
    border: "0px solid white",
    color: 'black',
    backgroundColor: 'white'
  },
  border: "0px solid white",
  color: 'black',
  backgroundColor: 'white',
  borderRadius: '24px 0 0 24px',
};

export const buttonRightSX = {
  "&:hover": {
    border: "0px solid white",
    color: 'black',
    backgroundColor: 'white'
  },
  border: "0px solid white",
  color: 'black',
  backgroundColor: 'white',
  borderRadius: '0 24px 24px 0',
};

export const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Manrope',
    Weight:'700',
    Size:'15px',
    lineHeight:'20.49px',

    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    margin:'20px 0px'
  },
  circular: {
    color: '#FA9917',
    float:'right'
  },
  circularMain: {
    color: '#FA9917',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  circularLoading: {
    color: '#FA9917',
  },
  filter: {
    float: 'right',
    marginTop: '-3px',
    marginRight: '20px',
  },
  buttonText: {
    backgroundColor: 'white',
    padding: '0px 7px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    marginTop: '0px',
    borderRadius: '0px',
    borderTopColor: '#E5E5E5',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderBottomColor: '#E5E5E5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    textTransform:'Capitalize !important',
  },
  // buttonLeft: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '20px !important',
  //   minWidth:'20px !important',
  //   borderRadius: '8px 0 0 8px',
  //   borderLeftColor: '#E5E5E5',
  //   borderLeftStyle: 'solid',
  //   borderLeftWidth: '1px',
  //   borderTopColor: '#E5E5E5',
  //   borderTopStyle: 'solid',
  //   borderTopWidth: '1px',
  //   borderBottomColor: '#E5E5E5',
  //   borderBottomStyle: 'solid',
  //   borderBottomWidth: '1px',
  //   borderRight: 0,
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  //   '& .MuiSvgIcon-root': {
  //     width: '17px',
  //     height: '12px',
  //     color:'#516573',
  //   }
  // },
  // buttonRight: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '20px !important',
  //   minWidth:'20px !important',
  //   borderRadius: '0 8px 8px 0',
  //   borderRightColor: '#E5E5E5',
  //   borderRightStyle: 'solid',
  //   borderRightWidth: '1px',
  //   borderTopColor: '#E5E5E5',
  //   borderTopStyle: 'solid',
  //   borderTopWidth: '1px',
  //   borderBottomColor: '#E5E5E5',
  //   borderBottomStyle: 'solid',
  //   borderBottomWidth: '1px',
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  //   '& .MuiSvgIcon-root': {
  //     width: '17px',
  //     height: '12px',
  //     color:'#516573',
      
  //   }
  // },
  card: {
    borderRadius: '8px',
    backgroundColor: '#f2f2f6',
    padding:'0px !important'
  },
  cardContent: {
    paddingTop: '0px',
    paddingBottom: '0px !important'
  },
  send: {
    float:'right',
    borderRadius: '8px',
    marginLeft:'5px', 
  },
  add: {
    float:'right', 
    display:'flex'
  },
  iconWidth: {
    minWidth: '30px'
  },
  icon:{
    paddingRight:'32px',
  },
  filterandsort:{
  '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover' : {
    backgroundColor: '#39F !important',
    color: 'white'
  },
  '& .MuiListItem-button:hover':{
    backgroundColor: '#39F !important',
    color: 'white',
  }
  },
  
  updatestatus:{
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover' : {
      backgroundColor: '#e1f5ed !important',
      color: 'black'
    },
    '& .MuiListItem-button:hover':{
      backgroundColor: '#e1f5ed !important',
      color: 'black',
    }
    },
  // buttonLeft: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '60px',
  //   borderRadius: '24px 0 0 24px',
  //   borderRight: 0,
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  // },
  // buttonRight: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '60px',
  //   borderRadius: '0 24px 24px 0',
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,    
  // },
}));

export const useStylesList = makeStyles({
  card: {
    borderRadius: '8px',
  },
  chipButton: {
    float: 'right',
    lineHeight: '0px',
  },
  nonChargeableStyle: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20.49px',
    borderRadius: '8px',
    display: 'block',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  font:{
    fontFamily:'Manrope',
    fontWeight:400,
    fontSize:'15px',
    lineHeight:'20.49px'
  },
  font2:{
    fontFamily:'Manrope',
    fontWeight:800,
    fontSize:'10px',
    lineHeight:'20.49px'
  }
});

export const styles = makeStyles(theme => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));