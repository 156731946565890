import React, { useState, useEffect } from 'react';
import {
  RegionsController,
  ConfigTSController,
  CustomersController,
  IntegrationController,
} from '../../Controller/Settings/ReuseController.js';
import {
  AwardsController,
  EmployeesFilterController,
  EmployeesController,
  TimezoneListController,
  RoleController,
  EmploymentTypeListController,
} from '../../Controller/Settings/Employees.js';
import { createData} from '../../views/Settings/styleemployee.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import linq from "linq";
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

let page = 1;
let integration = '';

export const EmployeesViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);

  const [employees, setEmployees, employeesRef] = useStateRef(null);
  const [customers, setCustomers, customersRef] = useStateRef('');
  const [customersFilterChosen, setCustomersFilterChosen, customersFilterChosenRef] = useStateRef([]);

  const [employeesFilter, setEmployeesFilter, employeesFilterRef] = useStateRef('');
  const [employeesFilterChosen, setEmployeesFilterChosen, employeesFilterChosenRef] = useStateRef([]);
  const [employeesLeavesChosen, setEmployeesLeavesChosen, employeesLeavesChosenRef] = useStateRef([]);
  
  const [open, setOpen] = React.useState(false);
  const [openRestore, setOpenRestore] = React.useState(false);
  const [openRoster, setOpenRoster] = React.useState(false);
  const [title, setTitle] = React.useState(null);

  const [employee, setEmployee] = useState(null);
  const [password, setPassword] = React.useState({
    password: "",
    showPassword: false
  });
  const [loading, setLoading] = useState(false);
  const [loadingRoster, setLoadingRoster] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);

  const [searchEmp, setSearchEmp] = useState('');
  const [totalPage, setTotalPage, totalPageRef] = useStateRef(0);
  
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(localStorage.getItem("employees_page")?localStorage.getItem("employees_page"):50);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [filter, setFilter, filterRef] = useStateRef(3);

  const [filterEmployee, setFilterEmployee, filterEmployeeRef] = useStateRef(false);
  const [deletedEmployee, setDeletedEmployee, deletedEmployeeRef] = useStateRef(false);
  const [emailNotificationStatus, setEmailNotificationStatus, emailNotificationStatusRef] = useStateRef(false);
  const [enableFlexibleRoster, setEnableFlexibleRoster, enableFlexibleRosterRef] = useStateRef(false);

  const [mode, setMode] = useState('');
  const [managementFeeStatus, setManagementFeeStatus, managementFeeStatusRef] = useStateRef(false);
  const [code, setCode, codeRef] = useStateRef('');

  const [firstName, setFirstName, firstNameRef] = useStateRef('');
  const [lastName, setLastName, lastNameRef] = useStateRef('');
  const [email, setEmail, emailRef] = useStateRef('');

  const [employmentType, setEmploymentType,employmentTypeRef] = useStateRef('');
  const [userLoading, setUserLoading] = React.useState(null);
  const [mobileNumber, setMobileNumber, mobileNumberRef] = useStateRef('');

  const [userID, setUserID] = React.useState('');
  const [employeeData, setEmployeeData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);
  const [timezone, setTimezone, timezoneRef] = useStateRef('');
  const [timezoneFilterChosen, setTimezoneFilterChosen, timezoneFilterChosenRef] = useStateRef('');
  const [employmentTypeFilterChosen, setEmploymentTypeFilterChosen, employmentTypeFilterChosenRef] = useStateRef('');
  const [regions, setRegions] = React.useState('');

  const [regionsData, setRegionsData] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [awardsData, setAwardsData] = useState(null);

  const [awardsEmployee, setAwardsEmployee, awardsEmployeeRef] = useStateRef('');
  const [customerAssignedTo, setCustomerAssignedTo, customerAssignedToRef] = useStateRef('');
  const [rows, setRows, rowsRef] = useStateRef([]);

  const [previous, setPrevious, previousRef] = useStateRef({});
  const [rdoHoursEnable, setRdoHoursEnable] = useState(false);
  const [bankedHoursEnable, setBankedHoursEnable] = useState(false);
  const [autoAdjustToRoster, setAutoAdjustToRoster] = useState(false);
  const [role, setRole] = useState('');
  const [roleFilterChosen, setRoleFilterChosen] = useStateRef([]);

  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  let disable = firstName === '' || lastName === '' || email === '';
  let isEditModeRoster = rows.find(o => o.isEditMode === true);
  let disableRoster = isEditModeRoster ? true : false;

  const check = JSON.parse(window.localStorage.getItem('configuration'));

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }

  const handleClickShowPassword = () => {
    setPassword({...password, showPassword: !password.showPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadEmployees();
    }
    
    const searchEmployee = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchEmp(eventData.text);
      loadEmployees(eventData.text);
    }

    const listener = EventEmitter.addListener('searchEmployee', searchEmployee);

    return () => {
      listener.remove();
      setInitialized(false)
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadRegions = async () => {
    try {
      var public_holiday_regions = await RegionsController();
      setRegionsData(public_holiday_regions.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  const loadConfiguration = async () => {
    try {
      var configuration_data = await ConfigTSController();
      setConfiguration(configuration_data.data[0]);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  /* eslint-disable array-callback-return*/
  const loadEmployees = async (data) => {
    setUserLoading(true);
    loadRegions();
    loadConfiguration();
    var EmployeesResults = await EmployeesController(data, page, filterRef.current, filterEmployeeRef.current, deletedEmployeeRef.current,pageNumberRef.current);
    setEmployees(EmployeesResults.data);
    setTotalPage(EmployeesResults.meta.filter_count);
    setAwardsData(await AwardsController());
    integration = await IntegrationController();
    setTimezone(await TimezoneListController());
    setCustomers(await CustomersController());
    setEmployeesFilter(await EmployeesFilterController());
    setRole(await RoleController());
    setEmploymentType(await EmploymentTypeListController());
    setUserLoading(false);
    setLoadingProcess(false);
  };

  const loadSyncEmployees = async () =>{
    setLoadingSync(true);

    try{
      var requestOptions = {
        method: 'GET'
      };
      
      await fetch("https://admin.interax.com.au/mfm/custom/sync/getdata/employees?access_token=2rfv43cVjKKZ7MFZIt8QIuh1", requestOptions)
        .then(response =>
          enqueueSnackbar('Sync Complete', { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 })
          )
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setLoadingSync(false);
    window.location.reload();
  };

  const getUser = async (id) => {
    setUserLoading(true);
    var User = await directus.getUser(id,{fields:'*.*'});
    setUserID(id);
    setFirstName(User.data.first_name);
    setLastName(User.data.last_name);
    setEmail(User.data.email);
    setTimezoneFilterChosen({ id: 0, name: User.data.timezone, value: User.data.timezone });
    setRoleFilterChosen({id:User.data.role.id, name: User.data.role.name});
    setUserLoading(false);
  };

  const handleClickOpen = (employee, mode) => {
    setOpen(true);
    if (mode === 'edit'){
      setMode('edit');
      getUser(employee.user.id);
      setCode(employee.code);
      setEmployeeData(employee);
      setRdoHoursEnable(employee.rdo_hours_enable);
      setBankedHoursEnable(employee.banked_hours_enable);
      setAutoAdjustToRoster(employee.auto_adjust_to_roster);
      setManagementFeeStatus(employee.management_fee);
      setEmploymentTypeFilterChosen({ id: 0, name: employee.type, value: employee.type });
      setMobileNumber(employee.mobile_number);
      setRegions(employee.public_holiday_region);
      setAwardsEmployee(employee.award);
      setCustomerAssignedTo(employee.customer_assigned_to);
      setEmailNotificationStatus(employee.enable_email_notifications);
      setEnableFlexibleRoster(employee.enable_flexible_roster);

      if (employee.customers.length > 0) {
        let result = [];
        employee.customers.map(data => {
          if(data.customer)
          {
            result = [...result, data.customer];
          }
        })
        setCustomersFilterChosen(result);
      }
      else { setCustomersFilterChosen([]); }

      if (employee.related.length > 0) {
        let result = [];
        employee.related.map(data => {
          if(data.child)
          {
          result = [...result, data.child];
          }
        })
        setEmployeesFilterChosen(result);
      }
      else { setEmployeesFilterChosen([]); }

      if (employee.leave_approver.length > 0) {
        let result = [];
        employee.leave_approver.map(data => {
          if(data.child)
          {
            result = [...result, data.child];
          }
        })
        setEmployeesLeavesChosen(result);
      }
      else { setEmployeesLeavesChosen([]); }

      setEmployee(employee);
      setTitle(employee.user.first_name + "'s Profile")
    }
    else if (mode === 'add'){
      setMode('add');
      setUserID('');
      setEmail('');
      setCode('');
      setFirstName('');
      setLastName('');
      setEmailNotificationStatus(false);
      setRdoHoursEnable(false);
      setBankedHoursEnable(false);
      setAutoAdjustToRoster(false);
      setManagementFeeStatus(false);
      setMobileNumber('');
      setCode('');
      setEmployee('');
      setEmployeeData('');
      setTitle('Add Employee');
      setEmployeesFilterChosen([]);
      setEmployeesLeavesChosen([]);
      setCustomersFilterChosen([]);
      setEmploymentTypeFilterChosen({ id: 0, name: 'Full-Time', value: 'Full-Time' });
      setPassword('');
      setRegions('');
      setTimezoneFilterChosen({ id: 0, name: localconfiguration.timezone, value: localconfiguration.timezone });
      setAwardsEmployee('');
      setCustomerAssignedTo('');
      setRoleFilterChosen({id:3, name:'Employee'});

    }
    else {
      setMode('delete');
      getUser(employee.user.id);
      setTitle('Delete ' + employee.user.first_name + ' ' + employee.user.last_name);
      setEmployee(employee);
      setEmployeeData(employee);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPassword({ password: null, showPassword: false });
    setEmployee(null);
  };

  const handleClickRestoreOpen = (employee) => {
    setOpenRestore(true);
    setTitle('Restore ' + employee.user.first_name + ' ' + employee.user.last_name);
    setEmployee(employee);
  };

  const handleRestoreClose = () => {
    setOpenRestore(false);
  };

  
  const handleClickOpenRoster = (employee, mode) => {
    setOpenRoster(true);
    setEmployee(employee)
    const rosters = employee.rosters;
    const listRosters = [{ id: 0, day: "monday" }, { id: 1, day: "tuesday" }, { id: 2, day: "wednesday" }, { id: 3, day: "thursday" }, { id: 4, day: "friday" }, { id: 5, day: "saturday" }, { id: 6, day: "sunday" }];
    const listDay = { 'monday': 0, 'tuesday': 1, 'wednesday': 2, 'thursday': 3, 'friday': 4, 'saturday': 5, 'sunday': 6 }

    if (rosters) {
      if (rosters.length > 0) {
        rosters.map((value) => {
          var indexList = listDay[value.day];
          listRosters[indexList] = {
            id: value.id,
            day: value.day,
            start_time: value.start_time,
            end_time: value.end_time,
            break: value.break,
            hours: null,
            ordinary_hours: value.ordinary_hours,
            rdo_accrual: value.rdo_accrual
          }
        })
      }
    }

    var rosterList = [];
    listRosters.map((value) => {
      rosterList.push(createData(value.id, value.day, value.start_time, value.end_time, value.break, value.hours, value.ordinary_hours, value.rdo_accrual));
    })
    setRows(rosterList)
  };

  const handleCloseRoster = () => {
    setOpenRoster(false);
    setEmployee('');
  };

  const SaveRoster = async () => {
    setLoadingRoster(true);
    const updateRows = [];
    const addRows = [];
    rowsRef.current.map((value) => {
      if (!Number.isInteger(value.id)) {
        updateRows.push({
          id: value.id,
          day: value.day,
          start_time: value.start_time !== "Invalid date" ? value.start_time : null,
          end_time: value.end_time !== "Invalid date" ? value.end_time : null,
          break: value.break,
          rdo_accrual: value.rdo_accrual !== "Invalid date" ? value.rdo_accrual : null,
          ordinary_hours: value.ordinary_hours
        })
      } else if (value.start_time && value.end_time && !value.isEditMode) {
        addRows.push({
          day: value.day,
          start_time: value.start_time !== "Invalid date" ? value.start_time : null,
          end_time: value.end_time !== "Invalid date" ? value.end_time : null,
          break: value.break ? value.break : 60,
          rdo_accrual: value.rdo_accrual !== "Invalid date" ? value.rdo_accrual : "00:24:00",
          ordinary_hours: value.ordinary_hours ? value.ordinary_hours : 7.6,
          employee: employee.id,
          status: "published"
        })
      }
    })

    if (updateRows.length > 0) {
      await directus.updateItems('roster', updateRows);
    }

    if (addRows.length > 0) {
      await directus.createItems('roster', addRows);
    }
    enqueueSnackbar("Roster updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });

    await loadEmployees();
    setLoadingRoster(false);
    setOpenRoster(false);
    setRows([]);
  }

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadEmployees(searchEmp);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("employees_page", JSON.parse(event.target.value));
    page = 1;
    loadEmployees(searchEmp);
  };

  const tickHandleChange = (event) => {
    if(event.target.name === 'deleted_employee'){
      setDeletedEmployee(event.target.checked);
    }
    else
    {
      setFilterEmployee(event.target.checked);
    }
    loadEmployees(searchEmp);
  };

  function filterStatus(input) {
    setFilter(input);
    loadEmployees(searchEmp);
    handleStatusClose();
    handleClose();
  }

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorE2(null);
  };

  const handleChangeInput = (e, type) => {
    if (type === "region") {
      setRegions(e)
    } else if (type === "awards") {
      setAwardsEmployee(e);
    } else if (type === "customer_assigned_to") {
      setCustomerAssignedTo(e);
    } else {
      const { name, value } = e.target;

      if (name === 'password') {
        setPassword({ ...password,password:value });
      }
      else if (name === 'firstname') {
        setFirstName(value);
      }
      else if (name === 'lastname') {
        setLastName(value);
      }
      else if (name === 'code') {
        setCode(value);
      }
      else if (name === 'email') {
        setEmail(value);
      }
      else if (name === 'mobile_number') {
        setMobileNumber(value);
      }
      else if (name === 'employmenttype') {
        setEmploymentTypeFilterChosen(value);
      }
      else if (name === 'user_role') {
        setRoleFilterChosen(value);
      }
      else if (name === 'management_fee') {
        setManagementFeeStatus(e.target.checked);
      } 
      else if (name === 'Email Notifications Enabled'){
        setEmailNotificationStatus(e.target.checked);
      }
      else if (name === 'Enable Flexible Hours'){
        setEnableFlexibleRoster(e.target.checked);
      }
      else if (name === 'RDO Hours Enable'){
        setRdoHoursEnable(e.target.checked);
      }
      else if (name === 'Banked Hours Enable')
      {
        setBankedHoursEnable(e.target.checked);
      }
      else
      {
      setAutoAdjustToRoster(e.target.checked);
      }
    }
  }

  const SaveEmployee = async () => {  
    setLoading(true);
    setLoadingProcess(true);
    if (mode === 'edit') 
    {
      //================================== Edit Users ===================================//
      if (firstNameRef.current !== '' || lastNameRef.current !== '' || emailRef.current !== '') {
        try {
          await directus.updateUser(userID, {
            first_name: firstNameRef.current,
            last_name: lastNameRef.current,
            email: emailRef.current,
            timezone: timezoneFilterChosenRef.current.value === null || timezoneFilterChosenRef.current.value === '' ? localconfiguration.timezone : timezoneFilterChosenRef.current.value,
            role:roleFilterChosen?roleFilterChosen.id:3
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (password.password !== null && password.password !== "" && password.password !== 'null') {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
          body: JSON.stringify({ password: password.password })
        };

        fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/auth/setpassword/` + employee.user.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            // console.log(result);
          });
      }

      //================================== Edit Employee ===================================//
        try {
          await directus.updateItem('employees', employee.id, { 
            type:employmentTypeFilterChosenRef.current?employmentTypeFilterChosenRef.current.value:'',
            mobile_number: mobileNumberRef.current,
            code: codeRef.current, 
            public_holiday_region: regions ? regions.id : null, 
            management_fee: managementFeeStatusRef.current, 
            award: awardsEmployeeRef.current ? awardsEmployeeRef.current.id : null, 
            customer_assigned_to: customerAssignedToRef.current ? customerAssignedToRef.current.id : null,
            enable_email_notifications: emailNotificationStatusRef.current,
            enable_flexible_roster: enableFlexibleRosterRef.current,
            rdo_hours_enable: rdoHoursEnable,
            banked_hours_enable: bankedHoursEnable,
            auto_adjust_to_roster:autoAdjustToRoster,
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }

      //================================== Edit Employee Customers Relations ===================================//
      let createData = [];
      customersFilterChosenRef.current.map(data => {
        const filterInput = linq.from(employee.customers)
          .where(x =>x.customer?x.customer.id === data.id:false).toArray();
        if (filterInput.length === 0) {
          createData = [...createData, { employee: employee.id, customer: data.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_employees', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteData = '';

      employee.customers.map(customer => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x =>customer.customer?x.id === customer.customer.id:false).toArray();

        if(customer.customer)
        {
          if (filterInput.length === 0) {
            if (deleteData === '') {
              deleteData = customer.customer.id;
            }
            else {
              deleteData = customer.customer.id + ',' + deleteData;
            }
          }
        }
      })

      if (deleteData !== '') {
        var CustomerEmployeeResult = await directus.getItems('customers_employees', {
          fields: '*.*',
          filter: {
            customer: { in: deleteData },
            employee: { eq: employee.id }
          }
        });

        let deleteInput = [];

        CustomerEmployeeResult.data.map(data => {
          deleteInput = [...deleteInput, data.id]
        })

        try {
          await directus.deleteItems('customers_employees', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      //================================== Edit Employee Related Relations ===================================//
      let createDataEmployees = [];

      employeesFilterChosenRef.current.map(data => {
        const filterInput = linq.from(employee.related)
          .where(x => x.child?x.child.id === data.id:false).toArray();

        if (filterInput.length === 0) {
          createDataEmployees = [...createDataEmployees, { parent: employee.id, child: data.id, status: 'published' }];
        }
      })

      if (createDataEmployees.length > 0) {
        try {
          await directus.createItems('employees_related', createDataEmployees);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteDataEmp = '';

      employee.related.map(employee => {

        const filterInput = linq.from(employeesFilterChosenRef.current)
          .where(x => employee.child?x.id === employee.child.id:false).toArray();

        if(employee.child)
        {
          if (filterInput.length === 0) {
            if (deleteDataEmp === '') {
              deleteDataEmp = employee.child.id;
            }
            else {
              deleteDataEmp = employee.child.id + ',' + deleteDataEmp;
            }
          }
        }
      })

      if (deleteDataEmp !== '') {
        var EmployeeEmployeeResult = await directus.getItems('employees_related', {
          fields: '*.*',
          filter: {
            child: { in: deleteDataEmp },
            parent: { eq: employee.id }
          }
        });

        let deleteInput = [];

        EmployeeEmployeeResult.data.map(data => {
          deleteInput = [...deleteInput, data.id];
        })

        try {
          await directus.deleteItems('employees_related', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      //================================== Edit Employee Leaves Relations ===================================//
      let createDataEmployeesLeave = [];

      employeesLeavesChosenRef.current.map(data => {

        const filterInput = linq.from(employee.leave_approver)
          .where(x => x.child?x.child.id === data.id:false).toArray();
 
        if (filterInput.length === 0) {
          createDataEmployeesLeave = [...createDataEmployeesLeave, { parent: employee.id, child: data.id, status: 'published' }];
        }
      })

      if (createDataEmployeesLeave.length > 0) {
        try {
          await directus.createItems('employees_leaves', createDataEmployeesLeave);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      
      let deleteDataEmpLeave = '';

      employee.leave_approver.map(employee => {

        const filterInput = linq.from(employeesLeavesChosenRef.current)
          .where(x => employee.child?x.id === employee.child.id:false).toArray();
        if (filterInput.length === 0) {
          if(employee.child)
          {
            if (deleteDataEmpLeave === '') {
              deleteDataEmpLeave = employee.child.id;
            }
            else {
              deleteDataEmpLeave = employee.child.id + ',' + deleteDataEmpLeave;
            }
          }
        }
      })

      if (deleteDataEmpLeave !== '') {
        try {
          var EmployeeLeaveResult = await directus.getItems('employees_leaves', {
            fields: '*.*',
            filter: {
              child: { in: deleteDataEmpLeave },
              parent: { eq: employee.id }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }

        let deleteInput = [];

        EmployeeLeaveResult.data.map(data => {
          deleteInput = [...deleteInput, data.id];
        })

        try {
          await directus.deleteItems('employees_leaves', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else if (mode === 'add') 
    {
      //================================== Add user ===================================//
      try {
        var addUser = await directus.createUser({
          first_name: firstNameRef.current,
          last_name: lastNameRef.current,
          email: emailRef.current,
          password: password.password,
          timezone: timezoneFilterChosenRef.current.value === null || timezoneFilterChosenRef.current.value === '' ? localconfiguration.timezone : timezoneFilterChosenRef.current.value,
          role: roleFilterChosen?roleFilterChosen.id:3,
          status: "active"
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      //================================== Add Employee ===================================//
      try {
        var addEmployees = await directus.createItems('employees', {
          code: code,
          type: employmentTypeFilterChosenRef.current?employmentTypeFilterChosenRef.current.value:'',
          mobile_number: mobileNumber,
          user: addUser.data.id,
          public_holiday_region: regions ? regions.id : null,
          management_fee: managementFeeStatusRef.current,
          award: awardsEmployeeRef.current ? awardsEmployeeRef.current.id : null,
          customer_assigned_to: customerAssignedToRef.current.id ? customerAssignedToRef.current.id : null,
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      let createData = [];
      let createDataEmployees = [];
      let createDataEmployeesLeave = [];


      //================================== Add Employee Customers Relations ===================================//
      if (customersFilterChosenRef.current.length > 0) {
        customersFilterChosenRef.current.map(data => {
          const filterInput = linq.from(employee.customers)
            .where(x => x.customer?x.customer.id === data.id:false).toArray();
          if (filterInput.length === 0) {
            createData = [...createData, { employee: addEmployees.data.id, customer: data.id, status: 'published' }];
          }
        })

        if (createData.length > 0){
          try {
            await directus.createItems('customers_employees', createData);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }
      }

      //================================== Add Employee Related Relations ===================================//
      if (employeesFilterChosenRef.current.length > 0) {
        employeesFilterChosenRef.current.map(data => {
          const filterInput = linq.from(employee.related)
            .where(x => x.child?x.child.id === data.id:false).toArray();

          if (filterInput.length === 0) {
            createDataEmployees = [...createDataEmployees, { parent: addEmployees.data.id, child: data.id, status: 'published' }];
          }
        })

        try {
          await directus.createItems('employees_related', createDataEmployees);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      //================================== Add Employee Related Relations ===================================//
      if (employeesLeavesChosenRef.current.length > 0) {
        employeesLeavesChosenRef.current.map(data => {
          const filterInput = linq.from(employee.leave_approver)
            .where(x => x.child?x.child.id === data.id:false).toArray();

          if (filterInput.length === 0) {
            createDataEmployeesLeave = [...createDataEmployeesLeave, { parent: addEmployees.data.id, child: data.id, status: 'published' }];
          }
        })
        try {
          await directus.createItems('employees_leaves', createDataEmployeesLeave);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else 
    {
      try {
        await directus.updateItem('employees', employee.id, { 
          status:'deleted'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
      

    }
    loadEmployees(searchEmp ? searchEmp : '');
    handleClose();
    setLoading(false);
  }

  const RestoreEmployee = async () => {  
    setLoading(true);
    setLoadingProcess(true);

      try {
        await directus.updateItem('employees', employee.id, { 
          status:'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    loadEmployees(searchEmp ? searchEmp : '');
    handleRestoreClose();
    setLoading(false);
  }


  const sendEmail = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/passwordreset/${employee.user.email}`, requestOptions)
      .then(res => console.log(res));
    handleClose();
  }

  const diffTime = (data) => {
    var date = moment(data).format('DD/MM/YYYY h:mm:ss A');
    var m = moment.utc(date, "DD-MM-YYYY h:mm:ss A"); // parse input as UTC
    var result = m.clone().local().format("YYYY-MM-DD HH:mm:ss");

    return moment().diff(result, 'hours', true);
  }

  //-------------- roster setting -----------
  const onToggleEditMode = (id, type) => {
    setRows(state => {
      return rowsRef.current.map(row => {
        if (row.id === id) {
          if(type !== "save"){
            setPrevious(state => {
              delete state[id];
              return state;
            });
            return { ...row, isEditMode: !row.isEditMode };
          }else{
            if((row.start_time && row.end_time) || (!row.start_time && !row.end_time)){
              setPrevious(state => {
                delete state[id];
                return state;
              });
              return { ...row, isEditMode: !row.isEditMode };
            }else{
              enqueueSnackbar("Start time and end time are required", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }
          
        }
        return row;
      });
    });
  };

  const onChange = (e, row, type) => {
    if (!previousRef.current[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }

    var value = null;
    var name = null;
    if (type === "start_time" || type === "end_time" || type === "rdo_accrual") {
      value = e ? e.format("HH:mm:ss") : null;
      name = type;
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    const { id } = row;
    const newRows = rowsRef.current.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = id => {
    const newRows = rowsRef.current.map(row => {
      if (row.id === id) {
        return previousRef.current[id]? previousRef.current[id] : row;
      }
      else{
        return row;
      }
    });
    setRows(newRows);

    onToggleEditMode(id);
  };
  /* eslint-enable array-callback-return*/

  return[
    //states
    {
      employees, anchorEl, anchorE2, integration,
      filter, localconfiguration, configuration, check,
      open, title, mode, userLoading,
      firstNameRef, lastNameRef, codeRef,
      emailRef, mobileNumberRef, timezoneRef, timezoneFilterChosenRef,
      regions, regionsData, customers, employeesFilter, 
      employeesFilterChosenRef, managementFeeStatusRef, awardsData, awardsEmployee,
      customerAssignedToRef, role, roleFilterChosen, customersFilterChosenRef,
      employeesLeavesChosenRef, password, emailNotificationStatusRef,
      rdoHoursEnable, bankedHoursEnable, autoAdjustToRoster,
      loading, disable, openRoster, openRestore,
      employee, rowsRef, loadingRoster, disableRoster,
      pageNumberRef, totalPage, totalPageRef, page, loadingSync,
      employmentTypeRef,employmentTypeFilterChosenRef, loadingProcessRef, 
      enableFlexibleRosterRef, 
    },
    //setStates
    {
      setCustomersFilterChosen, setEmployeesFilterChosen, setEmployeesLeavesChosen,
      setRoleFilterChosen, setTimezoneFilterChosen, setEmploymentTypeFilterChosen,
    },
    //function
    {
      SaveEmployee, handleCloseRoster, onToggleEditMode, onRevert,
      handleStatusClick, handleStatusClose, handleFilterClick, handleClickOpen,
      handleClickRestoreOpen,handleRestoreClose, RestoreEmployee,
      handleFilterClose, tickHandleChange, handleClickOpenRoster, handleClose,
      onChange, handleClickShowPassword, handleMouseDownPassword, SaveRoster,
      handleChange, handleChangeInput, diffTime, sendEmail,
      filterStatus, pageChange, loadSyncEmployees
    }
  ];
}

export default EmployeesViewModel;
